import {
  Button,
  Colors,
  NonIdealState,
  PanelProps,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import { FlexFieldGroup, showError, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import {
  BeneficiarySearchResultDto,
  Forme,
  SearchBeneficiaryDto,
} from "../../../api";
import { useCommonHooks } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import HopeViewCard from "../../../pages/common/hopeView/HopeViewCard";
import { AddButton } from "../../applicationButtons";
import { PreviousButton } from "../../applicationButtons/PreviousButton";
import { WarningText } from "../../WarningText";

const Container = styled.div`
  flex: 1;
  background-color: ${Colors.LIGHT_GRAY5};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  &.flex-one {
    flex: 1;
  }
`;

const CardsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 82vh;
  gap: 0.5rem;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export interface IBeneficiarySelectorResultsPanelProps {
  onSelect: (value: number, forme: Forme, idpersonne: number) => void;
  setBeneficiaryCreationMode: () => void;
}

export const BeneficiarySelectorResultsPanel: React.FunctionComponent<
  PanelProps<IBeneficiarySelectorResultsPanelProps>
> = ({ onSelect, setBeneficiaryCreationMode, ...props }) => {
  const { t, apis } = useCommonHooks();
  const { formik } = useFGContext<SearchBeneficiaryDto>();

  const fetchResults = React.useCallback(async () => {
    if (!formik?.values) {
      console.error("Crietrias empty !");
      return;
    }

    return await apis.beneficiaryPersons.searchBeneficiaryForSuivi(
      formik?.values
    );
  }, [apis.beneficiaryPersons, formik?.values]);
  const { data, isFetching } = useQuery(
    ["search-beneficiaries-for-suivi", formik?.values],
    fetchResults
  );

  const onPrevious = React.useCallback(() => {
    props.closePanel();
  }, [props]);

  const onSelectClick = React.useCallback(
    (selected: BeneficiarySearchResultDto) => {
      if (!selected) {
        showError(t(ETLCodes.NoItemSelected));
        return;
      }

      let id;
      switch (selected.forme) {
        case Forme.Apprenant:
          id = selected.idapprenant;
          break;
        case Forme.Representant:
          id = selected.idrepresentant;
          break;
        case Forme.Formateur:
          id = selected.idformateur;
          break;
        case Forme.ChefEntreprise:
          id = selected.idchefEntreprise;
          break;
        case Forme.Tuteur:
          id = selected.idtuteur;
          break;
        case Forme.Contact:
          id = selected.idcontact;
          break;
      }

      onSelect(id, selected.forme, selected.idpersonne);
    },
    [onSelect, t]
  );

  React.useEffect(() => {
    if (data?.results?.length === 1) {
      const selected = data?.results[0];
      onSelectClick(selected);
    }
  }, [data?.results, onSelectClick]);

  const noResultsPane = React.useMemo(
    () => (
      <NonIdealState
        title={t(ETLCodes.AucunBeneficiaireTrouve)}
        description={t(ETLCodes.VoulezVousCreerBeneficiaire)}
        icon="zoom-out"
        action={
          <ButtonContainer style={{ width: "100%" }}>
            <Button
              icon="chevron-left"
              text={t(ETLCodes.ModifierLaRecherche)}
              onClick={onPrevious}
            />
            <AddButton
              text={t(ETLCodes.AjouterBeneficiaire)}
              minimal={false}
              onClick={() => {
                setBeneficiaryCreationMode();
                onPrevious();
              }}
            />
          </ButtonContainer>
        }
      />
    ),
    [onPrevious, setBeneficiaryCreationMode, t]
  );

  return (
    <Container>
      <FlexFieldGroup>
        <CardsContainer>
          {isFetching ? (
            <Spinner />
          ) : data?.results?.length === 0 ? (
            noResultsPane
          ) : (
            data?.results?.map((row) => (
              <HopeViewCard
                title={
                  <>
                    <span>
                      {`${row.nom} ${row.prenom}` +
                        (!!row.denominationSociale
                          ? ` (${row.denominationSociale})`
                          : "")}
                    </span>
                    <Tag round intent="primary" large>
                      {row.forme}
                    </Tag>
                  </>
                }
                onClick={() => {
                  onSelectClick(row);
                }}
              >
                {!!row.dateNaissance && (
                  <div>
                    <span className="bold-label">
                      {t(ETLCodes.DateNaissance)}:{" "}
                    </span>
                    {row.dateNaissance
                      ? format(row.dateNaissance, "dd MMMM yyyy", {
                          locale: fr,
                        })
                      : "-"}
                  </div>
                )}
                {!!row.numeroBce && (
                  <div>
                    <span className="bold-label">
                      {t(ETLCodes.NumeroBce)}:{" "}
                    </span>
                    {row.numeroBce}
                  </div>
                )}
                <div>
                  <span className="bold-label">{t(ETLCodes.Telephone)}: </span>
                  {row.telephone}
                </div>
                <div>
                  <span className="bold-label">{t(ETLCodes.Gsm)}: </span>
                  {row.gsm}
                </div>
                <div>
                  <span className="bold-label">{t(ETLCodes.Email)}: </span>
                  {row.mail}
                </div>
              </HopeViewCard>
            ))
          )}
        </CardsContainer>
        <FooterContainer>
          {data?.hasMore && (
            <WarningText text={t(ETLCodes.MoreResultsAvailable)} />
          )}
        </FooterContainer>
        <ButtonContainer className="flex-one">
          <PreviousButton
            onClick={onPrevious}
            loading={isFetching}
            text={t(ETLCodes.ModifierLaRecherche)}
          />
        </ButtonContainer>
      </FlexFieldGroup>
    </Container>
  );
};

export default BeneficiarySelectorResultsPanel;
