import * as React from "react";
import { Button, Classes, Dialog } from "@blueprintjs/core";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { DialogResultButtonType, useDialogData } from "../contexts";

export const DialogRoot: React.FunctionComponent = () => {
  const { dialogData, hideDialog } = useDialogData();

  const isShown = React.useMemo(() => {
    return !!dialogData;
  }, [dialogData]);
  const { t } = useTl();
  const message = dialogData ? dialogData.message : "";
  const title = dialogData ? dialogData.title || t(ETLCodes.Confirmer) : "";

  const finalButtons = React.useMemo<DialogResultButtonType[]>(() => {
    if (dialogData && dialogData.buttons) return dialogData.buttons;
    return [
      {
        intent: "success",
        text: t(ETLCodes.Yes),
        resultType: "yes",
      },
      {
        intent: "danger",
        text: t(ETLCodes.No),
        resultType: "no",
      },
    ];
  }, [dialogData, t]);

  return (
    <Dialog
      isOpen={isShown}
      title={title}
      className={""}
      canOutsideClickClose={true}
      onClose={() => {
        hideDialog("cancel");
      }}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>{message}</p>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {finalButtons.map((bt, i) => {
            const { resultType, ...btProps } = bt;
            return (
              <Button
                key={i}
                {...btProps}
                onClick={() => hideDialog(resultType)}
              />
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};
