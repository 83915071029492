import * as React from "react";
import { useCommonHooks } from "../hooks";

interface INavigationContext {
  navigateWithBackReference: (route: string) => void;
  goBack: (defaultRoute: string) => void;
  clearBackRoute: () => void;
  backReference?: string | null;
}

const NavigationContext = React.createContext<INavigationContext>(null!);

interface NavigationProviderProps {
  children: React.ReactNode;
}

export const NavigationProvider: React.FunctionComponent<
  NavigationProviderProps
> = ({ children }) => {
  const { router } = useCommonHooks();

  const [previousPage, setPreviousPage] = React.useState<string | null>();

  const navigateWithBackReference = React.useCallback(
    (route: string) => {
      setPreviousPage(router.location.pathname);
      // Capture current page
      router.push(route);
    },
    [router]
  );

  const goBack = React.useCallback(
    (defaultRoute: string) => {
      if (previousPage) {
        router.push(previousPage);
        setPreviousPage(null);
      } else {
        router.push(defaultRoute);
      }
    },
    [previousPage, router]
  );

  const clearBackRoute = React.useCallback(() => {
    setPreviousPage(null);
  }, []);

  return (
    <NavigationContext.Provider
      value={{
        navigateWithBackReference,
        goBack,
        clearBackRoute,
        backReference: previousPage,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => React.useContext(NavigationContext);
