import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IValidateButtonProps extends ICustomButtonProps {}

export const ValidateButton: React.FunctionComponent<IValidateButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.SUCCESS,
  text,
  hideText,
  small,
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.TICK}
      minimal={minimal}
      onClick={onClick}
      text={!hideText ? text || t(ETLCodes.Validate) : null}
      intent={intent}
      loading={loading}
      disabled={disabled}
      small={small}
    />
  );
};
