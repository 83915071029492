import { Classes, Colors, Dialog, Elevation } from "@blueprintjs/core";
import classNames from "classnames";
import sortBy from "lodash.sortby";
import {
  BaseFilterCriteriaInfo,
  DataTable2,
  FlexFieldGroup,
  IDataTableColumn,
  IDataTableProps,
  ISearchPanelProps,
  IUseGridStateOptions,
  SearchPanel,
  SearchQuery,
  showError,
  showSuccess,
  SortKeysObject,
  useGridState,
  useSearchApi,
  useSearchExpressionApi,
} from "nsitools-react";
import * as React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { CanDoAction, Dropzone, DropzoneFile, ExportButton } from "../";
import { ActionCode, FileResponse, PaginatedSearchable } from "../../api";
import { useEventsContext } from "../../contexts";
import { useBrowserStorage, useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { IThemeData } from "../../theme";
import { downloadFileResponse } from "../../utils/downloadFileResponse";
import { formatDate } from "../../utils/formatDate";
import {
  DownloadButton,
  ImportButton,
  SaveButton,
} from "../applicationButtons";
import { CardFieldGroup, CardFieldGroupProps } from "../formGenerator";
import { nameof } from "../../utils";

const DialogBody = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.5rem;
  }
`;

export interface ISearchTableProps<
  TSearch extends PaginatedSearchable,
  TResults
> extends Pick<
      IDataTableProps<any>,
      "customizeRowStyle" | "onRowClick" | "onOpenSubComponent"
    >,
    Pick<CardFieldGroupProps, "title" | "rightElement">,
    Pick<
      ISearchPanelProps<any>,
      "overrideListValues" | "defaultCriterias" | "storageMode"
    >,
    Pick<
      IUseGridStateOptions<any>,
      "enableFilter" | "enableMultiSort" | "enablePagination"
    > {
  pageSize?: number;
  availablePageSizes?: number[];
  searchFunction: (
    searchQuery: SearchQuery<TSearch>
  ) => TResults | Promise<TResults>;
  exportFunction?: (searchQuery: SearchQuery<TSearch>) => Promise<FileResponse>;
  importFunction?: ({ fileName, data }) => void;
  getTemplateFunction?: () => Promise<FileResponse>;
  canDoExport?: ActionCode[];
  getCriteriasFunction?: () => Promise<BaseFilterCriteriaInfo[]>;
  columns: Array<IDataTableColumn>;
  criteriasTlPrefix?: string;
  sortKeys?: SortKeysObject;
  addButtonOnClick?: () => void;
  renderSubComponent?: (item: any) => React.ReactElement | string;
  defaultPageSize?: number;
  children?: (searchFn: Function) => React.ReactElement;
  showColumnSelector?: boolean;
  headerWrap?: boolean;
  withCards?: boolean;
  focusOnFilter?: boolean;
  highlightFilter?: boolean;
  showTitleBorderBottom?: boolean;
  largeTitle?: boolean;
  cardElevation?: Elevation;
  showPageSizeSelector?: boolean;
}

const StyledDataTable2 = styled(DataTable2)<{ theme: IThemeData }>`
  th {
    font-weight: 500 !important;
    color: ${Colors.DARK_GRAY5} !important;
    vertical-align: middle !important;
  }

  &.header-wrap th {
    white-space: break-spaces !important;
  }

  td {
    //font-weight: 500 !important;
  }

  &
    table.${Classes.HTML_TABLE}.${Classes.HTML_TABLE_STRIPED}
    tbody
    tr:nth-child(odd) {
    background: ${(props) => props.theme.tableStrip} !important;
  }

  & table.${Classes.HTML_TABLE}.${Classes.INTERACTIVE} tbody tr:hover {
    background-color: ${(props) => props.theme.tableRowHover} !important;
  }
`;

export function SearchTable<TSearch extends PaginatedSearchable, TResults>({
  searchFunction,
  exportFunction,
  importFunction,
  getTemplateFunction,
  canDoExport,
  getCriteriasFunction,
  columns,
  criteriasTlPrefix,
  overrideListValues,
  sortKeys = {},
  defaultCriterias = [],
  rightElement,
  customizeRowStyle,
  title,
  onRowClick,
  enablePagination = true,
  enableFilter = true,
  enableMultiSort = false,
  pageSize = 15,
  availablePageSizes = [15, 25, 50],
  storageMode = "sessionStorage",
  children,
  showColumnSelector = true,
  headerWrap = false,
  withCards = true,
  focusOnFilter = false,
  highlightFilter = false,
  showTitleBorderBottom = true,
  largeTitle = false,
  cardElevation,
  showPageSizeSelector = true,
  renderSubComponent,
}: ISearchTableProps<TSearch, TResults>) {
  const { router, currentLanguage, theme, t, tUnsafe, notifyGlobalError } =
    useCommonHooks();
  const location = useLocation();

  const [importDialogOpened, setImportDialogOpened] = React.useState<boolean>();
  const [uploadedFiles, setUploadedFile] = React.useState<DropzoneFile[]>([]);
  const [isImporting, setIsImporting] = React.useState(false);
  const [isGettingImportTemplate, setIsGettingImportTemplate] =
    React.useState(false);

  const getTemplateComputedFunction = React.useCallback(async () => {
    setIsGettingImportTemplate(true);
    try {
      var res = await getTemplateFunction();
      await downloadFileResponse(res);
    } catch (e) {
      console.error(e);
      showError(t(ETLCodes.Error));
    } finally {
      setIsGettingImportTemplate(false);
    }
  }, [getTemplateFunction, t]);

  const importComputedFunction = React.useCallback(async () => {
    setIsImporting(true);
    try {
      setIsImporting(true);
      if (uploadedFiles.length > 0) {
        await importFunction({
          data: uploadedFiles[0].file,
          fileName: uploadedFiles[0].name,
        });
      }
      setImportDialogOpened(false);
      showSuccess(t(ETLCodes.ImportSuccess));
    } catch (err) {
      notifyGlobalError(err, "", true);
    } finally {
      setIsImporting(false);
    }
  }, [importFunction, notifyGlobalError, t, uploadedFiles]);

  const { data: savedFilterSearch, set: setSavedFilterSearch } =
    useBrowserStorage(
      "grid_filter_" + location.pathname,
      storageMode === "localeStorage"
        ? "localeStorage"
        : storageMode === "sessionStorage"
        ? "sessionStorage"
        : "none"
    );

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination,
    enableFilter,
    enableMultiSort,
    availablePageSizes,
    pageSize,
    sortKeys: sortKeys,
    globalFilter: savedFilterSearch,
    storageOptions: {
      storageKey: "datagrid_" + router.pathname,
      columnCustomizationStorageMode:
        storageMode === "none" ? "state" : "localeStorage",
      globalFilterStorageMode:
        storageMode === "none" ? "state" : "sessionStorage",
      pageIndexStorageMode: storageMode === "none" ? "state" : "sessionStorage",
      pageSizeStorageMode: storageMode === "none" ? "state" : "localeStorage",
      sortKeysStorageMode: storageMode === "none" ? "state" : "sessionStorage",
    },
  });
  const { totalCount } = tableState;

  const innerSearchFunction = React.useCallback(
    (searchQuery: SearchQuery<TSearch>) => {
      if (searchQuery) {
        setSavedFilterSearch(searchQuery.criteria?.filter);
      }
      return searchFunction(searchQuery);
    },
    [searchFunction, setSavedFilterSearch]
  );

  const { search, loading, searchData } = useSearchExpressionApi({
    searchFunction: innerSearchFunction,
    tableState,
    initialSearch: !!!getCriteriasFunction,
    trimSearchObjectStringValues: true,
  });

  const OnRefreshSearchTablesEvent = React.useCallback(() => {
    search();
  }, [search]);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("RefreshSearchTables", OnRefreshSearchTablesEvent);
    return () =>
      unsubscribeEvent("RefreshSearchTables", OnRefreshSearchTablesEvent);
  }, [OnRefreshSearchTablesEvent, subscribeToEvent, unsubscribeEvent]);

  const finalSearchCriteriaFunction = React.useMemo(() => {
    if (getCriteriasFunction || currentLanguage) {
      return async () => {
        let criteriasFunction = await getCriteriasFunction();
        return sortBy(
          criteriasFunction,
          nameof<BaseFilterCriteriaInfo>("criteria")
        );
        return criteriasFunction;
      };
    }
  }, [currentLanguage, getCriteriasFunction]);

  const [exporting, setExporting] = React.useState(false);
  const exportGridData = React.useCallback(async () => {
    setExporting(true);
    try {
      const file = await exportFunction(searchData);
      await downloadFileResponse(file);
    } catch (e) {
      console.error(e);
      showError(t(ETLCodes.Error));
    }
    setExporting(false);
  }, [searchData, exportFunction, t]);

  const constructedRightElement = React.useMemo(() => {
    return (
      <>
        {rightElement}
        {exportFunction && (
          <CanDoAction actions={canDoExport}>
            <ExportButton onClick={exportGridData} loading={exporting} />
          </CanDoAction>
        )}
        {importFunction && (
          <CanDoAction actions={canDoExport}>
            <ImportButton onClick={() => setImportDialogOpened(true)} />
          </CanDoAction>
        )}
      </>
    );
  }, [
    canDoExport,
    exportFunction,
    exportGridData,
    exporting,
    importFunction,
    rightElement,
  ]);

  return (
    <FlexFieldGroup gap="0.5rem">
      {getCriteriasFunction && (
        <CardFieldGroup title={t(ETLCodes.TableCriterias)} withCard={withCards}>
          <SearchPanel
            getCriteriasFunc={finalSearchCriteriaFunction}
            defaultCriterias={defaultCriterias}
            onSearch={search}
            enableOrOnSameCriteria={true}
            translateFunc={tUnsafe}
            tlDataPrefix={criteriasTlPrefix}
            overrideListValues={overrideListValues}
            triggerInitialSearch={true}
            criteriaIntent="primary"
            storageMode={storageMode}
            storageKey={"search_" + location.pathname}
          />
        </CardFieldGroup>
      )}
      <CardFieldGroup
        title={title ? title : t(ETLCodes.TableResults, { count: totalCount })}
        rightElement={constructedRightElement}
        withCard={withCards}
        elevation={cardElevation}
        showBorderBottom={showTitleBorderBottom}
        largeTitle={largeTitle}
      >
        <StyledDataTable2
          theme={theme}
          tableState={tableState}
          loading={loading}
          columns={columns}
          showColumnSelector={showColumnSelector}
          customizeRowStyle={customizeRowStyle}
          onRowClick={onRowClick}
          filterMode="OnEnter"
          translateFunc={tUnsafe}
          formatDate={formatDate}
          className={classNames({ "header-wrap": headerWrap })}
          focusOnFilter={focusOnFilter}
          highlightFilter={highlightFilter}
          htmlTableOptions={{
            bordered: false,
            condensed: false,
            striped: true,
            interactive: !!onRowClick,
          }}
          showPageSizeSelector={showPageSizeSelector}
          renderSubComponent={renderSubComponent}
        />
      </CardFieldGroup>
      {children ? children(search) : null}

      <Dialog
        isOpen={importDialogOpened}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        title={t(ETLCodes.Import)}
        icon={"import"}
        onClose={() => {
          setImportDialogOpened(false);
        }}
      >
        <DialogBody>
          <Dropzone onFilesAdded={(f) => setUploadedFile(f)} />
          {!!getTemplateFunction ? (
            <DownloadButton
              text={t(ETLCodes.GetTemplate)}
              onClick={getTemplateComputedFunction}
              loading={isGettingImportTemplate}
            />
          ) : null}
          <SaveButton
            onClick={importComputedFunction}
            loading={isImporting}
            minimal={false}
            disabled={uploadedFiles.length === 0}
          />
        </DialogBody>
      </Dialog>
    </FlexFieldGroup>
  );
}
