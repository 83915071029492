import { Card, Colors } from "@blueprintjs/core";
import { lighten } from "polished";
import * as React from "react";
import styled from "styled-components";

const StyledCard = styled(Card)<{ flex: number }>`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  margin: 1px;

  flex: ${(props) => props.flex};

  &.highlighted {
    background-color: ${lighten(0.15, Colors.BLUE5)};
  }
`;

const CardTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 5%;

  &.spaced {
    justify-content: space-between;
  }
`;

const CardContent = styled.div`
  flex: 1;
  padding: 0 5%;
  font-size: 10.5pt;
  display: flex;
  flex-direction: column;
  gap: 5px;

  & .bold-label {
    font-weight: 600;
    font-size: 10.5pt;
  }
`;

export interface IHopeViewCardProps {
  title: React.ReactNode;
  children: React.ReactNode;
  titleSpace?: boolean;
  flex?: number;
  onClick?: () => void;
  highlighted?: boolean;
}

export const HopeViewCard: React.FunctionComponent<IHopeViewCardProps> = ({
  children,
  title,
  titleSpace = true,
  flex,
  onClick,
  highlighted,
}) => {
  return (
    <StyledCard
      flex={flex}
      interactive={!!onClick}
      onClick={onClick}
      className={highlighted && "highlighted"}
    >
      <CardTitle className={titleSpace && "spaced"}>{title}</CardTitle>
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default HopeViewCard;
