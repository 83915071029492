import {
  Card,
  Collapse,
  Colors,
  Elevation,
  Icon,
  MaybeElement,
  Spinner,
} from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";
import classNames from "classnames";
import { FlexFieldGroup, IFlexFieldGroupProps } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { useCommonHooks } from "../../hooks";
import { IThemeData } from "../../theme";

export interface CardFieldGroupProps
  extends Omit<IFlexFieldGroupProps, "wrapper"> {
  withCard?: boolean;
  title?: string;
  rightTitle?: string;
  rightElement?: React.ReactNode;
  leftElement?: React.ReactNode;
  icon?: IconName | MaybeElement;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
  className?: string;
  cardStyle?: React.CSSProperties;
  showBorderBottom?: boolean;
  largeTitle?: boolean;
  elevation?: Elevation;
  loading?: boolean;
}

const StyledCard = styled(Card)<{ theme: IThemeData }>`
  background: rgb(255, 255, 255);

  flex: 1;
  border-radius: 16px !important;

  /* padding: 1rem; */
  /* height: calc(100% - 1rem); COMMENTED TO FIT CONTENT */
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  /* border-bottom: 1px solid ${Colors.LIGHT_GRAY3}; */
  margin-bottom: 0.5rem;
  &.collapsible {
    cursor: pointer;
  }
  & .card-title {
    font-weight: 600;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.titleColor} !important;

    & > * + * {
      margin-left: 0.5rem;
    }
  }
  & .card-title.large {
    font-size: large;
  }
  //border-bottom: 1px solid lightgrey;
`;

export const CardFieldGroup: React.FunctionComponent<CardFieldGroupProps> = ({
  // fieldsetProps,
  withCard = true,
  title,
  rightTitle,
  rightElement,
  leftElement,
  icon,
  collapsible = false,
  defaultCollapsed = false,
  className,
  cardStyle,
  showBorderBottom = true,
  elevation,
  largeTitle = true,
  loading = false,
  ...props
}) => {
  const { theme } = useCommonHooks();

  const showTitleBar = React.useMemo(
    () => title || rightTitle || rightElement || leftElement,
    [leftElement, rightElement, rightTitle, title]
  );

  const [collapseOpen, setCollapseOpen] = React.useState(!defaultCollapsed);

  const content = React.useCallback(
    (c) => (
      <>
        {showTitleBar && (
          <TitleContainer
            onClick={(e: any) => {
              const className = e.target.className;
              if (
                typeof className === "string" &&
                className.includes("collapsible")
              ) {
                e.stopPropagation();
                setCollapseOpen((prev) => !prev);
              }
            }}
            style={{
              borderBottom: showBorderBottom ? "1px solid lightgrey" : "none",
            }}
            className={classNames({ collapsible: collapsible })}
          >
            {leftElement}
            {title && (
              <div
                className={classNames({
                  "card-title": true,
                  large: largeTitle,
                })}
              >
                {icon && (
                  <Icon
                    className={classNames({ collapsible: collapsible })}
                    icon={icon!}
                  />
                )}
                <span className={classNames({ collapsible: collapsible })}>
                  {title}
                </span>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {rightElement}
              {rightTitle && (
                <div
                  className={classNames({
                    "card-title": true,
                    large: largeTitle,
                  })}
                >
                  {icon && (
                    <Icon
                      className={classNames({ collapsible: collapsible })}
                      icon={icon!}
                    />
                  )}
                  <span className={classNames({ collapsible: collapsible })}>
                    {rightTitle}
                  </span>
                </div>
              )}
              {collapsible && (
                <Icon
                  icon={collapseOpen ? "chevron-up" : "chevron-down"}
                  color={theme.primaryColor}
                  onClick={() => setCollapseOpen((prev) => !prev)}
                />
              )}
            </div>
          </TitleContainer>
        )}
        <Collapse isOpen={collapseOpen} keepChildrenMounted>
          {loading ? <Spinner /> : c}
        </Collapse>
      </>
    ),
    [
      collapseOpen,
      collapsible,
      icon,
      largeTitle,
      leftElement,
      loading,
      rightElement,
      rightTitle,
      showBorderBottom,
      showTitleBar,
      theme.primaryColor,
      title,
    ]
  );

  return (
    <FlexFieldGroup
      wrapper={(c) =>
        withCard ? (
          <StyledCard
            theme={theme}
            className={className}
            style={cardStyle}
            elevation={elevation}
          >
            {content(c)}
          </StyledCard>
        ) : (
          content(c)
        )
      }
      gap="0.5rem"
      {...props}
    />
  );
};
