import {
  Alignment,
  AnchorButton,
  Classes,
  Collapse,
  Colors,
  Divider,
  Icon,
  IconName,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classnames from "classnames";
import { lighten, rgba } from "polished";
import * as React from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { useAuth, useNavigationContext } from "../../contexts";
import { useCommonHooks } from "../../hooks";
import { IThemeData } from "../../theme";
import { CollapseAllContext } from "./CollapseAllContext";
import { INavigationItem } from "./INavigationItem";

const ButtonStyled = styled(AnchorButton)<{ theme: IThemeData; level: number }>`
  /* Change colors for customer */
  margin: 0;
  border-radius: 0 !important;
  color: ${(props) => props.theme.highlightColor || "black"} !important;
  line-height: 1.15;

  background-color: ${({ level, theme }) =>
    lighten(level / 40, theme.menuColor)} !important;

  padding-left: ${({ level }) => 1 + level * 0.7}em !important;

  &:hover {
    background-color: ${(props) =>
      props.theme.selectionMenuColor || Colors.BLUE3} !important;
    color: ${(props) => props.theme.highlightColor || "black"} !important;

    & .${Classes.ICON} {
      color: ${Colors.GRAY5} !important;
      display: block;
    }
  }

  & .${Classes.ICON} {
    color: ${(props) => props.theme.menuIconColor};
  }

  &.is-active {
    font-weight: bold;
    text-decoration: blink !important;
    /* border-bottom: 3px solid; */
    /* border-color: ${(props) =>
      props.theme.selectionMenuColor || Colors.BLUE3} !important; */
    background-image: linear-gradient(
      to right,
      ${(props) => rgba(props.theme.selectionMenuColor, 0.8)},
      ${(props) => rgba(props.theme.selectionMenuColor, 0.3)}
    ) !important;
    color: ${Colors.GRAY5} !important;

    & .${Classes.ICON} {
      color: ${Colors.GRAY5} !important;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: ${(props) => props.theme.navMenuWidth}px;
  height: auto;
  transition: width 250ms ease-out;

  &.closed {
    width: 0 !important;
  }

  & .${Classes.MENU} {
    padding: 0 !important;
  }
`;

export interface IMenuItemProps {
  item: INavigationItem;
  level: number;
  disabled?: boolean;
  maxAutoCollapseLevel?: number;
  onNavigation?: () => void;
  isMenuOpen?: boolean;
}

export const MenuItem: React.FunctionComponent<IMenuItemProps> = ({
  item,
  level,
  disabled,
  maxAutoCollapseLevel = 999,
  onNavigation,
  isMenuOpen = true,
}) => {
  const { theme, router } = useCommonHooks();
  const isActive = React.useMemo(
    () =>
      item.route &&
      router.pathname.startsWith(item.route) &&
      (item.route !== "/" || router.pathname.endsWith(item.route)),
    [item, router]
  );

  const [isExpanded, setIsExpanded] = React.useState(!!item.defaultOpened);
  const { t } = useCommonHooks();
  const id = React.useMemo(() => uuidv4(), []);
  const { hasAction } = useAuth();
  const { openedMenuId, setMenuOpenedId } =
    React.useContext(CollapseAllContext);

  const { clearBackRoute } = useNavigationContext();

  React.useEffect(() => {
    const hasItemWithCurrentRoute = (itm: INavigationItem) => {
      if (itm.route && router.pathname.includes(itm.route)) {
        return true;
      }
      if (itm.items) {
        return itm.items.filter((i) => hasItemWithCurrentRoute(i)).length > 0;
      }
      return false;
    };

    if (hasItemWithCurrentRoute(item)) {
      setIsExpanded(true);
      setMenuOpenedId({ level, id });
    }
  }, [id, item, level, router.pathname, setMenuOpenedId]);

  React.useEffect(() => {
    if (
      openedMenuId?.level === level &&
      openedMenuId?.id !== id &&
      level <= maxAutoCollapseLevel
    ) {
      setIsExpanded(false);
    }
  }, [id, level, maxAutoCollapseLevel, openedMenuId]);

  const hasChildren = React.useMemo(() => {
    return item.items && item.items.length > 0;
  }, [item]);

  const collapseIcon = React.useMemo<IconName>(() => {
    if (hasChildren) {
      return isExpanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT;
    } else {
      return IconNames.BLANK;
    }
  }, [hasChildren, isExpanded]);

  const btnRef = React.useRef<HTMLAnchorElement>();
  const checkTargeted = React.useCallback(
    (target: HTMLElement, nodes: NodeListOf<ChildNode>) => {
      let targeted = false;
      for (const n of nodes) {
        if (target.isEqualNode(n)) {
          targeted = true;
          break;
        }
        if (n.childNodes.length > 0) {
          targeted = checkTargeted(target, n.childNodes);
        }

        if (targeted) break;
      }

      return targeted;
    },
    []
  );
  const onClick = React.useCallback(
    (event: any) => {
      if (!event.target.isEqualNode(btnRef.current)) {
        let targeted = false;
        for (const n of btnRef.current.childNodes) {
          if (event.target.isEqualNode(n)) {
            targeted = true;
            break;
          }
          if (n.childNodes.length > 0) {
            targeted = checkTargeted(event.target, n.childNodes);
          }

          if (targeted) break;
        }

        if (!targeted) return;
      }
      if (hasChildren) {
        if (!isExpanded) {
          setMenuOpenedId({ level, id });
        }
        setIsExpanded((e) => !e);
      } else if (item.route) {
        if (event.ctrlKey || event.shiftKey) return;
        clearBackRoute();
        if (item.external) {
          window.open(item.route, "_blank");
        } else {
          router.push(item.route);
        }
        if (onNavigation) {
          onNavigation();
        }
      }
    },
    [
      hasChildren,
      item.route,
      item.external,
      checkTargeted,
      isExpanded,
      setMenuOpenedId,
      level,
      id,
      clearBackRoute,
      onNavigation,
      router,
    ]
  );

  const finalSubItems = React.useMemo(() => {
    if (!item.items) return [];
    return item.items.filter((d) => hasAction(d.actions));
  }, [hasAction, item.items]);

  if (finalSubItems.length === 0 && hasChildren) {
    return null;
  }

  return (
    <>
      {item.startGroupTitle && (
        <div style={{ color: "red" }}>{item.startGroupTitle}</div>
      )}
      {hasChildren && level === 0 && (
        <Divider style={{ borderColor: Colors.GRAY1 }} />
      )}
      <ButtonStyled
        elementRef={btnRef}
        theme={theme}
        level={level}
        text={typeof item.name === "string" ? item.name : t(item.name)}
        icon={
          item.alternativeIcon ? (
            <i className={`${Classes.ICON} ${item.alternativeIcon}`} />
          ) : (
            item.icon
          )
        }
        rightIcon={
          <>
            {item.rightElement}
            {collapseIcon !== IconNames.BLANK && <Icon icon={collapseIcon} />}
          </>
        }
        onClick={onClick}
        minimal={true}
        className={classnames([
          { "has-children": hasChildren, "is-active": isActive },
        ])}
        alignText={Alignment.LEFT}
        small={false}
        disabled={disabled}
      />
      {item.collapsedElement}
      <Collapse isOpen={isExpanded}>
        <Container className={!isMenuOpen && "closed"}>
          {finalSubItems.map((itm, index) => (
            <MenuItem
              key={index}
              item={itm}
              level={level + 1}
              disabled={itm.disabled}
              maxAutoCollapseLevel={maxAutoCollapseLevel}
              onNavigation={onNavigation}
            />
          ))}
        </Container>
      </Collapse>
    </>
  );
};
