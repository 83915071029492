import { useQuery } from "react-query";
import { SystemApi } from "../../api";
import { baseUrl } from "../../apiConfig";

export function useFrontendSettings() {
  const {
    data: frontendSettings,
    isFetching: loadingFrontendSettings,
    refetch: refreshFrontendSettings,
  } = useQuery(
    "fe-settings",
    async () => {
      const api = new SystemApi({
        basePath: baseUrl,
        middlewares: [],
      });
      return api.geFrontendSettings();
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );

  return {
    frontendSettings,
    loadingFrontendSettings,
    refreshFrontendSettings,
  };
}
