import { Card } from "@blueprintjs/core";
import { FGDateInput } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  position: relative;
  flex: 1;
  padding-top: 2rem;
`;

const LabelContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  font-weight: bold;
`;

export interface ICardReadOnlyDateInputProps {
  label: string;
  name: string;
}

export const CardReadOnlyDateInput: React.FunctionComponent<
  ICardReadOnlyDateInputProps
> = ({ label, name }) => {
  return (
    <StyledCard>
      <LabelContainer>{label}</LabelContainer>
      <FGDateInput name={name} noLabel />
    </StyledCard>
  );
};

export default CardReadOnlyDateInput;
