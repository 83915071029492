import { Classes, Collapse } from "@blueprintjs/core";
import { FGTextInput } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";

import { NavFavouriteDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { useCrudApi } from "../../hooks/useCrudApi";
import { ETLCodes } from "../../locales";
import { CustomFormGenerator } from "../CustomFormGenerator";

const Container = styled.div`
  & .form-container {
    padding: 1rem !important;
  }

  & .right {
    display: flex;
  }

  & .right,
  .popover-btn {
    flex: 1;
    & .${Classes.BUTTON_TEXT} {
      flex: none;
    }
  }
  & .left {
    display: none;
  }
`;

export interface INavFavouriteCollapseProps {
  isOpen: boolean;
  navFavourite: NavFavouriteDto;
  onClose: (saved: boolean) => void;
}

export const NavFavouriteCollapse: React.FunctionComponent<
  INavFavouriteCollapseProps
> = ({ navFavourite, onClose, isOpen }) => {
  const { apis, t } = useCommonHooks();
  const { data, validationErrors, onSave, isSaving } = useCrudApi({
    queryKey: ["nav-favourite", navFavourite?.idnavFavourite],
    fetchFn: async () => {
      return navFavourite?.idnavFavourite > 0
        ? navFavourite
        : NavFavouriteDto.fromJS({});
    },
    saveFn: async (navFavouriteToSave: NavFavouriteDto) => {
      return apis.navFavourites.saveNavFavourite(navFavouriteToSave);
    },
    onSaved: (navFavouriteSaved: NavFavouriteDto) => {
      onClose(true);
    },
    serverValidationRootKey: "NavFavouriteDto",
  });

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string().required(t(ETLCodes.Required)),
        url: Yup.string()
          .required(t(ETLCodes.Required))
          .url(t(ETLCodes.MustBeUrl)),
      }),
    [t]
  );

  const popoverRef = React.useRef<HTMLElement>();
  if (popoverRef.current) {
    popoverRef.current.onkeyup = (e: KeyboardEvent) => {
      if (e.code === "Space" || e.code === "Enter") {
        e.stopPropagation();
      }
    };
  }

  return (
    <Collapse isOpen={isOpen}>
      {navFavourite && (
        <Container>
          <CustomFormGenerator
            initialValues={data}
            onSubmit={onSave}
            saving={isSaving}
            editable={true}
            editMode={true}
            deleteButtonPosition="none"
            validationErrors={validationErrors}
            validationSchema={validationSchema}
            saveButtonClassName="popover-btn"
            cancelButtonClassName="popover-btn"
            onCancel={() => onClose(false)}
          >
            <FGTextInput
              name="label"
              placeholder={t(ETLCodes.Libelle)}
              noLabel
              maxLength={100}
            />
            <FGTextInput
              name="url"
              placeholder={t(ETLCodes.URL)}
              noLabel
              maxLength={500}
            />
          </CustomFormGenerator>
        </Container>
      )}
    </Collapse>
  );
};

export default NavFavouriteCollapse;
