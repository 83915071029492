import { Button, Intent } from "@blueprintjs/core";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IPreviousButtonProps extends ICustomButtonProps {}

export const PreviousButton: React.FunctionComponent<IPreviousButtonProps> = ({
  minimal,
  onClick,
  disabled,
  loading,
  intent = Intent.PRIMARY,
  style,
  className,
  text,
}) => {
  const { t } = useTl();

  return (
    <Button
      icon="chevron-left"
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : text ?? t(ETLCodes.Precedent)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      style={style}
      className={className}
    />
  );
};
