import { b64toBlob } from ".";
import { FileResponse } from "../api";

export async function downloadBlob(file: Blob, fileName: string) {
  const url = URL.createObjectURL(file);
  const anchor: HTMLAnchorElement = document.createElement("a");
  anchor.download = fileName;
  anchor.href = url;
  anchor.click();
  setTimeout(() => URL.revokeObjectURL(url));
}

export async function downloadFileResponse(response: FileResponse) {
  await downloadBlob(response.data, response.fileName);
}

export async function downloadBase64(b64: string, fileName: string) {
  const cleanB64 = b64.includes("base64,") ? b64.split("base64,")[1] : b64;
  const blob = b64toBlob(cleanB64);
  await downloadBlob(blob, fileName);
}
