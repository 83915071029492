import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "normalize.css/normalize.css";

import { Classes, NonIdealState } from "@blueprintjs/core";
import { Classes as Classes2 } from "@blueprintjs/popover2";
import * as React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import styled from "styled-components";

import { BaseAppNavigation } from "./app-navigation";
import { AppRouter } from "./AppRouter";
import {
  DialogRoot,
  Footer,
  FullPageLoader,
  Header,
  NavigationMenu,
} from "./components";
import { AppError } from "./components/AppError";
import { useAuth, useGlobalData } from "./contexts";
import { useCommonHooks, useTl } from "./hooks";
import { ETLCodes } from "./locales";
import { IThemeData } from "./theme";
import { AppTheme } from "./components/AppTheme";

const AppContainer = styled.div<{ isdark?: boolean; theme: IThemeData }>`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  background-color: ${(props) => props.theme.mainBackgroundColor};

  & .${Classes.TAB_PANEL} {
    width: 100%;
  }

  & .${Classes.TABS} *:focus {
    outline: none !important;
  }

  & .app-drawer {
    & .${Classes.DRAWER_BODY} {
      background: ${(props) => props.theme.menuColor} !important;
    }

    & .${Classes.DRAWER_FOOTER} {
      background: ${(props) => props.theme.menuColor} !important;
    }
  }

  &
    .${Classes.CARD},
    .${Classes.POPOVER},
    .${Classes.POPOVER_CONTENT},
    .${Classes2.POPOVER2},
    .${Classes2.POPOVER2_CONTENT} {
    border-radius: 0.35rem !important;
    &.${Classes.ELEVATION_0} {
      box-shadow: 0px 0px 15px 0px rgba(82, 63, 105, 0.05) !important;
    }
  }

  & :focus {
    outline: none !important;
  }
`;

const ContentContainer = styled.div<{ theme: IThemeData }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-wrap: wrap;
`;

const NavigationContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
`;

const FooterContainer = styled.div``;

export interface IAppProps {}

export const App: React.FunctionComponent<IAppProps> = () => {
  const { isLoading: authLoading, error: authError, isLoggedIn } = useAuth();

  const [menuVisible, setMenuVisible] = React.useState(true);
  const [darkMode, setDarkMode] = React.useState(false);

  const { loading, appVersion, backendNotReachable } = useGlobalData();
  const { theme, smallScreen } = useCommonHooks();

  React.useEffect(() => {
    setMenuVisible(!smallScreen);
  }, [smallScreen]);

  const appIsLoading = React.useMemo(
    () => loading || authLoading,
    [loading, authLoading]
  );

  const { t } = useTl();

  return (
    <>
      <AppContainer theme={theme}>
        <AppTheme />
        {appIsLoading ? (
          <FullPageLoader />
        ) : (
          <AppError>
            <Header
              toggleLeftMenu={() => {
                setMenuVisible((s) => !s);
              }}
              isDark={darkMode}
              onDarkSwitchChange={() => setDarkMode(!darkMode)}
            />
            <ContentContainer className="page">
              {backendNotReachable ? (
                <NonIdealState
                  icon="th-disconnect"
                  title={"Backend not reachable"}
                />
              ) : authError || !isLoggedIn ? (
                <NonIdealState
                  icon="error"
                  title={t(ETLCodes.NotAuthorized)}
                  description={t(ETLCodes.UserNotAuthorizedMessage)}
                />
              ) : (
                <>
                  <NavigationContainer>
                    <NavigationMenu
                      enableQuickAccess={false}
                      navigationMenu={BaseAppNavigation}
                      collapseOtherOnSelection={true}
                      maxAutoCollapseLevel={0}
                      isOpen={menuVisible}
                    />
                  </NavigationContainer>
                  <AppRouter />
                </>
              )}
            </ContentContainer>
            <FooterContainer>
              <Footer versionLoading={loading} version={appVersion} />
            </FooterContainer>
            <DialogRoot />
          </AppError>
        )}
        <ReactQueryDevtools />
      </AppContainer>
    </>
  );
};
