import { getIn } from "formik";
import {
  FGSelectInput,
  IFGSelectInputProps,
  useFGContext,
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { ReferentialItemDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";

export interface ICitiesSelectProps
  extends Omit<
    IFGSelectInputProps<any>,
    "items" | "loading" | "displayField" | "valueField"
  > {
  countryFieldName?: string;
}

export const CitiesSelect: React.FunctionComponent<ICitiesSelectProps> = ({
  countryFieldName,
  ...fgSelectInputProps
}) => {
  const { t, apis } = useCommonHooks();
  const { formik } = useFGContext();

  const countryCode = React.useMemo(
    () => (!!countryFieldName ? getIn(formik?.values, countryFieldName) : null),
    [countryFieldName, formik?.values]
  );

  const { data, isFetching } = useQuery(
    ["ref-cities", countryCode],
    async () => {
      const cities = await apis.referentials.cities(countryCode);
      return cities.map((r) => ({ ...r, idValue: r.idValue }));
    }
  );

  return (
    <FGSelectInput
      label={fgSelectInputProps.label || t(ETLCodes.Localites)}
      items={data}
      loading={isFetching}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      {...fgSelectInputProps}
    />
  );
};
