import "flickity/dist/flickity.min.css";

import { Button, IconName, NonIdealState } from "@blueprintjs/core";
import * as React from "react";
import Flickity from "react-flickity-component";
import styled from "styled-components";

import HopeViewCard from "../../pages/common/hopeView/HopeViewCard";

const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const CardsContainer = styled.div`
  padding: 1px;
  flex: 1;
  & .cardContent-data {
    color: white;
    text-transform: uppercase;
  }

  & .flickity-slider {
    & > div {
      width: 95%;
    }
    & > div + div {
      margin-left: 1rem;
    }
  }
`;

const ButtonSection = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export interface ICardCarouselProps<T> {
  data: T[];
  nonIdealStateIcon?: IconName;
  nonIdealStateDescription: string;
  title: (item: T, index: number) => string;
  cardContent: (item: T, index: number) => React.ReactNode;
}

export function CardCarousel<T>({
  data,
  nonIdealStateIcon = "zoom-out",
  nonIdealStateDescription,
  title,
  cardContent,
}: React.PropsWithChildren<ICardCarouselProps<T>>) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [flickityRef, setFlickityRef] = React.useState<Flickity>(null);

  const displaySectionButtons = React.useMemo(
    () => data && data.length > 1,
    [data]
  );

  const nextPage = React.useCallback(() => {
    setSelectedIndex(selectedIndex + 1);
    flickityRef.select(selectedIndex + 1);
  }, [flickityRef, selectedIndex]);

  const previousPage = React.useCallback(() => {
    setSelectedIndex(selectedIndex - 1);
    flickityRef.select(selectedIndex - 1);
  }, [flickityRef, selectedIndex]);

  return (
    <Container>
      <CardsContainer>
        {data?.length > 0 ? (
          <Flickity
            flickityRef={setFlickityRef}
            className={"carousel"}
            elementType={"div"}
            options={{
              pageDots: false,
              initialIndex: selectedIndex,
              prevNextButtons: false,
              draggable: false,
              percentPosition: true,
              contain: true,
              groupCells: true,
              cellAlign: "left",
            }}
          >
            {data.map((item, index) => (
              <div>
                <HopeViewCard title={title(item, index)}>
                  {cardContent(item, index)}
                </HopeViewCard>
              </div>
            ))}
          </Flickity>
        ) : (
          <NonIdealState
            icon={nonIdealStateIcon}
            description={nonIdealStateDescription}
          />
        )}
      </CardsContainer>
      {displaySectionButtons && (
        <ButtonSection>
          <Button
            disabled={selectedIndex === 0}
            icon="chevron-left"
            onClick={previousPage}
            minimal
          />
          <Button
            disabled={selectedIndex >= data.length - 1}
            icon="chevron-right"
            onClick={nextPage}
            minimal
          />
        </ButtonSection>
      )}
    </Container>
  );
}

export default CardCarousel;
