import * as React from "react";

export function useBrowserStorage(
  key,
  storageMode: "sessionStorage" | "localeStorage" | "none",
  defaultValue: string = null
) {
  const data = React.useMemo(() => {
    if (storageMode === "sessionStorage") {
      return sessionStorage.getItem(key) ?? "";
    }
    if (storageMode === "localeStorage") {
      return localStorage.getItem(key) ?? "";
    }
    return "";
  }, [key, storageMode]);

  const clear = React.useCallback(() => {
    if (storageMode === "sessionStorage") {
      sessionStorage.removeItem(key);
    }
    if (storageMode === "localeStorage") {
      localStorage.removeItem(key);
    }
  }, [key, storageMode]);

  const set = React.useCallback(
    (filter: string) => {
      if (!filter) clear();
      else {
        if (storageMode === "sessionStorage") {
          sessionStorage.setItem(key, filter);
        }
        if (storageMode === "localeStorage") {
          localStorage.setItem(key, filter);
        }
      }
    },
    [clear, key, storageMode]
  );

  React.useEffect(() => {
    if (!data && defaultValue) {
      set(defaultValue);
    }
  }, [defaultValue, set, data]);

  return { data, set, clear };
}
