import * as React from "react";
import {
  Button,
  Classes,
  IconName,
  Intent,
  MaybeElement,
} from "@blueprintjs/core";
import styled from "styled-components";
import { IThemeData } from "../../theme";
import { ICustomButtonProps } from "./ICustomButtonProps";
import { useCommonHooks } from "../../hooks";

const StyledButton = styled(Button)<{ theme: IThemeData }>`
  color: ${(props) => props.theme.primaryColor} !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  min-height: 0 !important;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    box-shadow: none !important;
  }

  &:focus {
    outline: none !important;
  }

  & .${Classes.ICON} {
    color: ${(props) => props.theme.primaryColor} !important;
  }
`;

export interface ILinkButtonProps
  extends Omit<ICustomButtonProps, "minimal" | "intent"> {
  icon?: IconName | MaybeElement;
  rightIcon?: IconName | MaybeElement;
}

export const LinkButton: React.FunctionComponent<ILinkButtonProps> = ({
  onClick,
  loading,
  disabled,
  text,
  icon,
  rightIcon,
}) => {
  const { theme } = useCommonHooks();
  return (
    <StyledButton
      theme={theme}
      icon={icon}
      title={text}
      minimal={false}
      onClick={onClick}
      text={text}
      intent={Intent.NONE}
      loading={loading}
      disabled={disabled}
      rightIcon={rightIcon}
    />
  );
};
