import * as React from "react";
import { HashRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import { FullPageLoader } from "./components";
import {
  AuthProvider,
  DialogProvider,
  EventsProvider,
  GlobalDataProvider,
  HopeViewVerticalTabsContextProvider,
  NavigationProvider,
} from "./contexts";
import { HotkeysProvider } from "@blueprintjs/core";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // query options
      refetchOnWindowFocus: false,
    },
    mutations: {
      // mutation options
    },
  },
});

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <DialogProvider>
            <HashRouter>
              <EventsProvider>
                <AuthProvider>
                  <HotkeysProvider>
                    <GlobalDataProvider>
                      <NavigationProvider>
                        <HopeViewVerticalTabsContextProvider>
                          {props.children}
                        </HopeViewVerticalTabsContextProvider>
                      </NavigationProvider>
                    </GlobalDataProvider>
                  </HotkeysProvider>
                </AuthProvider>
              </EventsProvider>
            </HashRouter>
          </DialogProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </React.Suspense>
  );
};
