import { Button, Classes, Menu, MenuItem } from "@blueprintjs/core";
import { Classes as Classes2, Popover2 } from "@blueprintjs/popover2";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { ERoutes } from "../AppRouter";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";
import UserIcon from "./UserIcon";

export interface UserMenuProps {}

const Container = styled.div`
  margin-left: 0.5em;
  background-color: ${(props) => props.color || "none"};

  & .${Classes2.POPOVER2}, .${Classes2.POPOVER2_CONTENT}, .${Classes.MENU} {
    border-radius: 16px !important;
  }
`;

const StyledButton = styled(Button)<{ color: string }>`
  &:hover,
  :active {
    background: none !important;
  }
`;

export const UserMenu: React.FunctionComponent<UserMenuProps> = (props) => {
  const { t, logout, theme, userColor, router, apis } = useCommonHooks();

  const { data, isFetching } = useQuery(
    ["azure-profile"],
    async () => await apis.user.azureProfile()
  );

  return (
    <Container color={theme.userColor}>
      <Popover2
        usePortal={true}
        content={
          <Menu>
            <MenuItem
              icon="user"
              text={t(ETLCodes.MyProfile)}
              onClick={() => router.push(ERoutes.profile)}
            />
            <MenuItem
              icon="log-out"
              text={t(ETLCodes.Logout)}
              onClick={() => logout()}
            />
          </Menu>
        }
        position="bottom-right"
        minimal
      >
        <StyledButton
          rightIcon="chevron-down"
          loading={isFetching}
          icon={
            <div style={{ display: "flex", justifyContent: "center" }}>
              {data?.photo ? (
                <img
                  src={`data:image/png;base64,${data?.photo}`}
                  alt="Profil"
                  style={{ maxHeight: "30px", borderRadius: "50%" }}
                />
              ) : (
                <UserIcon />
              )}
            </div>
          }
          minimal
          small
          color={userColor}
        />
      </Popover2>
    </Container>
  );
};
