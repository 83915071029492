import { FGSelectInput, IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { ReferentialItemDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";

export interface IGenderSelectProps
  extends Omit<
    IFGSelectInputProps<any>,
    "items" | "loading" | "displayField" | "valueField"
  > {}

export const GenderSelect: React.FunctionComponent<IGenderSelectProps> = ({
  ...fgSelectInputProps
}) => {
  const { t, apis } = useCommonHooks();

  const { data: genders, isFetching } = useQuery("ref-genders", async () => {
    return await apis.referentials.genders();
  });

  return (
    <FGSelectInput
      label={fgSelectInputProps.label || t(ETLCodes.Genre)}
      items={genders}
      loading={isFetching}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      {...fgSelectInputProps}
    />
  );
};
