import { Button, Classes, Colors, Menu } from "@blueprintjs/core";
import * as React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { NavFavouriteDto } from "../../api";
import { NAV_ITEM_TO_REPLACE } from "../../app-navigation";
import { useAuth } from "../../contexts";
import { useCommonHooks } from "../../hooks";
import { IThemeData } from "../../theme";
import { CollapseAllContext, OpenedMenuId } from "./CollapseAllContext";
import { INavigationItem } from "./INavigationItem";
import { IMenuItemProps, MenuItem } from "./MenuItem";
import { NavFavouriteCollapse } from "./NavFavouriteCollapse";
import { QuickAccess } from "./QuickAccess";

export const Container = styled.div<{ theme: IThemeData }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: ${(props) => props.theme.navMenuWidth}px;
  height: auto;
  transition: width 250ms ease-out;

  &.closed {
    width: 0 !important;
  }
`;

interface INavigationMenuProps extends Pick<IMenuItemProps, "onNavigation"> {
  navigationMenu: INavigationItem[];
  collapseOtherOnSelection?: boolean;
  maxAutoCollapseLevel?: number;
  enableQuickAccess?: boolean;
  isOpen?: boolean;
}

const MenuStyled = styled(Menu)<{ theme: IThemeData }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  padding-top: 1rem;
  border-radius: 0;
  width: ${(props) => props.theme.navMenuWidth}px;
  background-color: ${(props) =>
    props.theme.menuColor || Colors.DARK_GRAY4} !important;
  transition: width 250ms ease-out;

  &.closed {
    width: 0 !important;
  }

  & .no-padding {
    padding: 0px !important;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 7px;
  margin-right: 10px;

  & .${Classes.ICON} {
    display: none;
  }
`;

export const NavigationMenu: React.FunctionComponent<INavigationMenuProps> = ({
  navigationMenu,
  collapseOtherOnSelection: closeOtherOnSelection,
  maxAutoCollapseLevel,
  onNavigation,
  enableQuickAccess = true,
  isOpen = true,
}) => {
  const {
    theme,
    user,
    reloadUser,
    apis: { navFavourites },
  } = useCommonHooks();
  const [openedMenuId, setMenuOpenedId] = React.useState<OpenedMenuId | null>(
    null
  );
  const { pathname } = useLocation();
  const { hasAction } = useAuth();

  const [currentNavFavourite, setCurrentNavFavourite] =
    React.useState<NavFavouriteDto>(null);

  const deleteNavFarourite = React.useCallback(
    async (idnavFavourite: number) => {
      await navFavourites.deleteNavFavourite(idnavFavourite);
      reloadUser();
    },
    [navFavourites, reloadUser]
  );

  const onNavFavouriteClosed = React.useCallback(
    (saved: boolean) => {
      if (saved) reloadUser();

      setCurrentNavFavourite(null);
    },
    [reloadUser]
  );

  const replaceNavItem = React.useCallback(
    (navItem: INavigationItem) => {
      let items = [];
      let rightElement = null;

      switch (navItem.toReplaceName) {
        case NAV_ITEM_TO_REPLACE.FAVOURITES:
          items = user?.navFavourites.map(
            (nf) =>
              ({
                name: nf.label,
                external: true,
                route: nf.url,
                rightElement: (
                  <IconsContainer>
                    <Button
                      small
                      icon="edit"
                      minimal
                      className="no-padding"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentNavFavourite(nf);
                      }}
                    />
                    <Button
                      small
                      icon="trash"
                      minimal
                      className="no-padding"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteNavFarourite(nf.idnavFavourite);
                      }}
                    />
                  </IconsContainer>
                ),
              } as INavigationItem)
          );
          rightElement = (
            <Button
              small
              icon="add"
              minimal
              className="no-padding"
              style={{ marginRight: 5 }}
              onClick={(e) => {
                e.stopPropagation();
                setCurrentNavFavourite(
                  NavFavouriteDto.fromJS({ idnavFavourite: 0 })
                );
              }}
            />
          );
          break;
      }

      return {
        ...navItem,
        items,
        rightElement,
        collapsedElement: (
          <NavFavouriteCollapse
            isOpen={!!currentNavFavourite}
            navFavourite={currentNavFavourite}
            onClose={onNavFavouriteClosed}
          />
        ),
      } as INavigationItem;
    },
    [
      currentNavFavourite,
      deleteNavFarourite,
      onNavFavouriteClosed,
      user?.navFavourites,
    ]
  );

  const checkOpened = React.useCallback(
    (navItem: INavigationItem): INavigationItem => {
      let opened = false;
      if (
        (navItem?.route && pathname.startsWith(navItem.route)) ||
        navItem.defaultOpened
      ) {
        opened = true;
      }
      if (!!navItem.toReplaceName) {
        navItem = replaceNavItem(navItem);
      }
      const nextNavItems = navItem?.items ? navItem.items.map(checkOpened) : [];
      opened = opened || nextNavItems.filter((d) => d.defaultOpened).length > 0;

      return {
        ...navItem,
        defaultOpened: opened,
        items: nextNavItems,
      };
    },
    [pathname, replaceNavItem]
  );

  const navigationMenuFinal = React.useMemo(() => {
    // Keep menu original state for root route
    // if (pathname === "/") return navigationMenu;
    return navigationMenu
      .filter((nm) => {
        let actionOk = hasAction(nm.actions || []);
        return nm.actions ? actionOk : true;
      })
      .map(checkOpened);
  }, [checkOpened, hasAction, navigationMenu]);

  return (
    <Container theme={theme} className={!isOpen && "closed"}>
      <CollapseAllContext.Provider
        value={{
          openedMenuId,
          setMenuOpenedId: closeOtherOnSelection ? setMenuOpenedId : () => {},
        }}
      >
        {enableQuickAccess && (
          <QuickAccess
            navigationMenu={navigationMenu}
            onNavigation={onNavigation}
          />
        )}
        <MenuStyled large={false} theme={theme} className={!isOpen && "closed"}>
          {navigationMenuFinal.map((n, i) => (
            <MenuItem
              isMenuOpen={isOpen}
              item={n}
              key={i}
              level={0}
              disabled={n.disabled}
              maxAutoCollapseLevel={maxAutoCollapseLevel}
              onNavigation={onNavigation}
            />
          ))}
        </MenuStyled>
      </CollapseAllContext.Provider>
    </Container>
  );
};
