import * as React from "react";
import { FGCustomInput, IFGCustomInputProps } from "nsitools-react";
import styled from "styled-components";

export interface FGReadOnlyValueProps extends Omit<IFGCustomInputProps, ""> {}

const Container = styled.div``;

const FGReadOnlyValue: React.FC<FGReadOnlyValueProps> = ({
  children,
  ...inputProps
}) => {
  return (
    <FGCustomInput {...inputProps}>
      {(ctx) => <Container>{children(ctx)}</Container>}
    </FGCustomInput>
  );
};

export default FGReadOnlyValue;
