import { Card, Dialog, Elevation, Icon, MaybeElement } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import { FGCustomInput, useFGContext } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { DeleteButton, DownloadButton, EditButton, MessageBubble } from ".";
import { useTl } from "../hooks";
import { ETLCodes } from "../locales";
import { downloadBase64 } from "../utils/downloadFileResponse";
import { formatDate } from "../utils/formatDate";

const StyledCard = styled(Card)`
  flex: 1;
  padding: 10px !important;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledImg = styled.img`
  max-height: 9.5rem;

  &.image-only {
    max-height: 20rem;
  }

  &:hover {
    cursor: pointer;
  }
`;

const StyledDialog = styled(Dialog)`
  background: none !important;
  box-shadow: none !important;

  width: 1000px;
  height: 800px;
  justify-content: center;
  padding-top: 0;
`;

const DialogImg = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

export interface IImagePanelProps {
  editMode: boolean;
  validationErrors?: any;
  validationErrorsKey?: string;
  fieldName: string;
  defaultIcon?: IconName | MaybeElement;
  imageOnly?: boolean;
  imageNamePrefix?: string;
}

export const ImagePanel: React.FunctionComponent<IImagePanelProps> = ({
  editMode,
  validationErrors,
  validationErrorsKey,
  fieldName,
  defaultIcon,
  imageOnly = false,
  imageNamePrefix = "MySyndic_Picture",
}) => {
  const { formik } = useFGContext();
  const { t } = useTl();
  const [imgDialogOpen, setImgDialogOpen] = React.useState(false);

  const hiddenFileInput = React.useRef(null);

  const deleteImage = React.useCallback(() => {
    formik.setFieldValue(fieldName, null);
  }, [fieldName, formik]);

  const updateImage = React.useCallback(
    (newImage: any) => {
      formik.setFieldValue(fieldName, newImage);
    },
    [fieldName, formik]
  );
  const onSelectFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          updateImage(reader.result.toString());
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    },
    [updateImage]
  );

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const downloadFile = React.useCallback(() => {
    const base64 = formik?.values?.[fieldName];
    const extension = base64.split(";")[0].split("/")[1];
    downloadBase64(
      base64,
      `${imageNamePrefix}_${formatDate(new Date())}.${extension}`
    );
  }, [fieldName, formik?.values, imageNamePrefix]);

  const innerComponent = React.useMemo(
    () => (
      <FGCustomInput noLabel>
        {(ctx) => (
          <InnerContainer>
            {/* JUST TO FLEX */}
            <div></div>
            {formik?.values?.[fieldName] ? (
              <StyledImg
                className={imageOnly && "image-only"}
                src={formik?.values?.[fieldName]}
                alt={t(ETLCodes.Image)}
                onClick={() => setImgDialogOpen(true)}
              />
            ) : (
              <Icon
                iconSize={imageOnly ? 300 : 136.52}
                icon={defaultIcon ?? IconNames.OFFICE}
                style={{ marginBottom: "10px" }}
              />
            )}
            {editMode && (
              <ButtonContainer>
                <EditButton onClick={handleClick} />
                <input
                  type="file"
                  accept="image/*"
                  onChange={onSelectFile}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />
                <DownloadButton
                  onClick={downloadFile}
                  disabled={!formik?.values?.[fieldName]}
                />
                <DeleteButton
                  onDelete={deleteImage}
                  showConfirm={false}
                  disabled={!formik?.values?.[fieldName]}
                />
              </ButtonContainer>
            )}
          </InnerContainer>
        )}
      </FGCustomInput>
    ),
    [
      defaultIcon,
      deleteImage,
      downloadFile,
      editMode,
      fieldName,
      formik?.values,
      imageOnly,
      onSelectFile,
      t,
    ]
  );

  return (
    <>
      {imageOnly ? (
        innerComponent
      ) : (
        <StyledCard>
          {validationErrors && validationErrors[validationErrorsKey] && (
            <MessageBubble
              innerMessage={t(
                ETLCodes.ImageIsNotInTheCorrectFormatErrorMessage
              )}
            />
          )}
          {innerComponent}
        </StyledCard>
      )}
      <StyledDialog
        isOpen={imgDialogOpen}
        onClose={() => setImgDialogOpen(false)}
      >
        <DialogImg
          src={formik?.values?.[fieldName]}
          alt={t(ETLCodes.Image)}
          onClick={() => setImgDialogOpen(false)}
        />
      </StyledDialog>
    </>
  );
};
