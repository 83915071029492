import {
  Button,
  Classes,
  Collapse,
  Colors,
  Icon,
  IconSize,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import classNames from "classnames";
import { format } from "date-fns";
import fr from "date-fns/locale/fr";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { HopeViewSuiviActionType, InboxRowDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";

export interface IInboxMenuItemProps {
  row: InboxRowDto;
  interactive?: boolean;
  onClick?: (row: InboxRowDto) => void;
  archived?: boolean;
  refetch?: () => void;
  child?: boolean;
}

const StyledMenuItem = styled(MenuItem)`µ
  display: flex;
  padding: 0;

  &.${Classes.MENU_ITEM}:hover {
    background-color: transparent !important;
    cursor: default !important;
  }
  &.has-children {
    border-bottom: solid 1px ${Colors.LIGHT_GRAY1};
  }
`;

const TextContainer = styled.div`
  display: flex;
  color: ${Colors.DARK_GRAY5};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;

  &.large {
    width: 3rem !important;
  }

  &.interactive {
    cursor: pointer;
    & .collapse-icon {
      color: ${Colors.GRAY1} !important;
    }

    &:hover {
      background-color: rgba(143, 153, 168, 0.15) !important;
      & .collapse-icon {
        color: ${Colors.BLUE5} !important;
      }
    }
  }
`;

const ContentContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 1rem 1rem 1rem 5px;

  &:hover {
    background-color: rgba(143, 153, 168, 0.15) !important;
  }

  &:hover .on-hover-button {
    display: flex;
  }
`;

const OnHoverButton = styled(Button)`
  display: none;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding-bottom: 3px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 1.17em;
`;

const Description = styled.span`
  display: flex;
  font-weight: 500;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`;

export const InboxMenuItem: React.FunctionComponent<IInboxMenuItemProps> = ({
  row,
  onClick,
  interactive = false,
  archived = false,
  refetch = () => {},
  child = false,
}) => {
  const {
    t,
    apis: { inbox },
  } = useCommonHooks();
  const [isOpen, setIsOpen] = React.useState(false);

  const fetchByBenef = React.useCallback(async () => {
    return await inbox.searchByIdbeneficiary(row);
  }, [inbox, row]);

  const { data, isFetching, isFetched } = useQuery(
    ["inbox-row-children", row],
    fetchByBenef,
    { enabled: isOpen && interactive }
  );

  const hasChildren = React.useMemo(
    () => !isFetching && isFetched && data?.length > 0,
    [data?.length, isFetched, isFetching]
  );

  const title = React.useMemo(() => {
    let val = row.userFullName;

    if (!!row.service) val += ` (${row.service})`;
    else if (!!row.centre) val += ` (${row.centre})`;
    else if (!!row.instituteManagement) val += ` (${row.instituteManagement})`;
    else if (!!row.otherPartner) val += ` (${row.otherPartner})`;

    return val;
  }, [
    row.centre,
    row.instituteManagement,
    row.otherPartner,
    row.service,
    row.userFullName,
  ]);

  const description = React.useMemo(() => {
    let val = null;
    switch (row.type) {
      case HopeViewSuiviActionType.Suivi:
        val = row.contentHidden ? t(ETLCodes.ContenuSuiviCache) : row.title;
        break;
      case HopeViewSuiviActionType.Action:
        val = t(ETLCodes.ActionDeTypeAjoutee, { type: row.title });
        break;
      case HopeViewSuiviActionType.Realisation:
        val = t(ETLCodes.ActionDeTypeRealisee, { type: row.title });
        break;
    }

    return row.beneficiary + (!!val ? ` - ${val}` : "");
  }, [row.beneficiary, row.contentHidden, row.title, row.type, t]);

  const [saving, setSaving] = React.useState(false);
  const saveInboxArchive = React.useCallback(
    async (dto: InboxRowDto) => {
      try {
        setSaving(true);
        await inbox.saveInboxArchive(dto);
      } catch (error) {}
      setSaving(false);
      refetch();
    },
    [inbox, refetch]
  );

  return (
    <>
      <StyledMenuItem
        onClick={() => onClick(row)}
        className={hasChildren && isOpen && "has-children"}
        text={
          <TextContainer>
            <IconContainer
              className={classNames({
                large: child,
                interactive: interactive && (!isOpen || hasChildren),
              })}
              onClick={
                interactive &&
                ((e) => {
                  e.stopPropagation();
                  setIsOpen((prev) => !prev);
                })
              }
            >
              {isFetching ? (
                <Spinner size={16} />
              ) : (
                (!isOpen || hasChildren) &&
                interactive && (
                  <Icon
                    icon={isOpen ? "caret-down" : "caret-right"}
                    className="collapse-icon"
                    size={IconSize.LARGE}
                  />
                )
              )}
            </IconContainer>
            <ContentContainer>
              <TitleContainer>
                <Title>{title}</Title>
                <span>
                  {row.type === HopeViewSuiviActionType.Action &&
                    `${t(ETLCodes.Echeance)}: `}
                  {!!row.date &&
                    format(row.date, "dd MMMM yyyy", { locale: fr })}
                </span>
              </TitleContainer>
              <Description>
                {description}
                {!archived && (
                  <OnHoverButton
                    className={interactive && "on-hover-button"}
                    icon={IconNames.Archive}
                    onClick={(e) => {
                      e.stopPropagation();
                      saveInboxArchive(row);
                    }}
                    loading={saving}
                  />
                )}
              </Description>
            </ContentContainer>
          </TextContainer>
        }
      />
      {hasChildren && (
        <Collapse isOpen={isOpen}>
          {data?.map((r, index) => (
            <InboxMenuItem key={index} row={r} child />
          ))}
        </Collapse>
      )}
    </>
  );
};

export default InboxMenuItem;
