import { lowerFirst } from "lodash";
import { showError, ValidationErrorsType } from "nsitools-react";
import React from "react";
import { ResultError } from "../api";
import { useTl } from "./useTl";

export const GlobalErrorField = "_GLOBAL_";

export function useManageValidationErrors() {
  const { tUnsafe } = useTl();
  const parseValidationErrors = React.useCallback(
    (error: any, rootKey = "", showMessage = false): ValidationErrorsType => {
      let object: Array<any>;
      if (Array.isArray(error)) {
        object = error;
      } else {
        if (!error.response) {
          return {} as ValidationErrorsType;
        }
        object = JSON.parse(error.response);
      }

      if (Array.isArray(object)) {
        return object.map(ResultError.fromJS).reduce((curr, next) => {
          if (next.errorCode) {
            let propertyName = next.propertyName;
            if (propertyName === "" && next.errorCode) {
              propertyName = GlobalErrorField;
            }
            if (rootKey !== "" && propertyName.startsWith(rootKey + "."))
              propertyName = propertyName.replace(rootKey + ".", "");
            propertyName = propertyName
              .split(".")
              .map((p) => lowerFirst(p))
              .join(".");

            let existingErrors = [];

            if (curr.hasOwnProperty(propertyName))
              existingErrors = curr[propertyName];

            curr[propertyName] = [
              ...existingErrors,
              next.errorCode
                ? `${tUnsafe("ServerValidationError_" + next.errorCode)}${
                    showMessage ? " - " + next.errorMessage : ""
                  }`
                : next.errorMessage,
            ];
          }
          return curr;
        }, {} as ValidationErrorsType);
      }
      return {} as ValidationErrorsType;
    },
    [tUnsafe]
  );

  const notifyGlobalError = React.useCallback(
    (error: any, rootKey = "", showMessage: boolean = false) => {
      const errors = parseValidationErrors(error, rootKey, showMessage);
      if (errors[GlobalErrorField]) {
        showError(
          <ul>
            {errors[GlobalErrorField].map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>,
          {
            timeout: 0,
          }
        );
      }
    },
    [parseValidationErrors]
  );
  return {
    parseValidationErrors,
    notifyGlobalError,
  };
}
