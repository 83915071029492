import { Alignment, BreadcrumbProps, Classes, Navbar } from "@blueprintjs/core";
import { Classes as Classes2 } from "@blueprintjs/popover2";
import classNames from "classnames";
import { ButtonContainer, Loading } from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { ERoutes } from "../AppRouter";
import { useCommonHooks, useScrollWithShadow } from "../hooks";
import { SiteTheme } from "../theme";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { AppError } from "./AppError";
import { AddButton } from "./applicationButtons";

export interface CustomBreadcrumbProps extends Omit<BreadcrumbProps, "href"> {
  route?: string | ERoutes;
}

export interface PageBaseProps {
  breadCrumbs?: CustomBreadcrumbProps[];
  isLoading?: boolean;
  overflowMode?: "none" | "all" | "tabs-panel";
  stickyContent?: React.ReactNode;
  children: React.ReactNode;
  onAddButtonClick?: () => void;
}

const Container = styled.div<{ padding: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  align-self: stretch;
  padding: ${(props) => props.padding};
  overflow: hidden;

  .${Classes.TABS}.${Classes.VERTICAL} > .${Classes.TAB_PANEL} {
    margin-top: 0;
    padding-left: 0.5rem;
  }

  .${Classes.TABS} > .${Classes.TAB_PANEL} {
    margin-top: 0.5rem;
  }
`;

const NonStickyContent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 1px 5% 1rem 5%;

  &.overflow {
    overflow: auto;
  }

  &.tab-panel-overflow {
    overflow: hidden;
    .${Classes.TABS} {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .${Classes.TABS} > .${Classes.TAB_PANEL} {
      overflow: auto;
    }
  }
`;

const NavBarStyled = styled(Navbar)<{ theme: SiteTheme; boxShadow: string }>`
  position: relative;
  height: 50px !important;
  background-color: transparent !important;
  color: ${(props) => props.theme.mainTextColor} !important;
  box-shadow: ${(props) => props.boxShadow};

  & .${Classes.BREADCRUMB} {
    color: ${(props) => props.theme.mainTextColor} !important;
  }

  & > .${Classes.NAVBAR_GROUP} {
    height: 50px;
  }

  & .${Classes2.POPOVER2}, .${Classes2.POPOVER2_CONTENT}, .${Classes.MENU} {
    border-radius: 16px !important;
  }
`;

export const PageBase: React.FunctionComponent<PageBaseProps> = ({
  breadCrumbs,
  children,
  isLoading = false,
  overflowMode = "all",
  stickyContent = null,
  onAddButtonClick,
}) => {
  const {
    setBaseBreadCrumb,
    setDetailBreadCrumb,
    breadCrumbs: finalBreadCrumbs,
    router,
    smallScreen,
    theme,
  } = useCommonHooks();
  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  React.useEffect(() => {
    setDetailBreadCrumb([]);
  }, [setDetailBreadCrumb]);

  React.useEffect(() => {
    if (breadCrumbs) {
      const nextBr: BreadcrumbProps[] = breadCrumbs?.map((br) =>
        br.route
          ? {
              ...br,
              onClick: () => {
                if (br.route) {
                  router.push(br.route);
                }
              },
            }
          : { ...br }
      );
      setBaseBreadCrumb(nextBr);
    } else {
      setBaseBreadCrumb([]);
    }
  }, [breadCrumbs, router, setBaseBreadCrumb]);

  return (
    <Container padding={smallScreen ? "0.5rem" : "0"}>
      {stickyContent && <div>{stickyContent}</div>}
      <NavBarStyled theme={theme} boxShadow={boxShadow}>
        <div style={{ position: "relative" }}>
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>
              <AppBreadcrumb items={finalBreadCrumbs} />
            </Navbar.Heading>
            {!!onAddButtonClick && (
              <ButtonContainer>
                <AddButton outlined onClick={onAddButtonClick} />
              </ButtonContainer>
            )}
          </Navbar.Group>
        </div>
      </NavBarStyled>
      <NonStickyContent
        className={classNames({
          overflow: overflowMode === "all",
          "tab-panel-overflow": overflowMode === "tabs-panel",
        })}
        onScroll={onScrollHandler}
      >
        <AppError>
          <Loading isLoading={isLoading}>{children}</Loading>
        </AppError>
      </NonStickyContent>
    </Container>
  );
};
