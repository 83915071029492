import {
  FGMaskInput,
  FGSelectInput,
  FGTextInput,
  FlexFieldGroup,
  useFGContext,
} from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { ReferentialItemDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";
import { SwitchButton } from "../applicationButtons";

export interface ISiegeSocialSuggestSelectProps {
  idName: string;
  denominationName: string;
  bceName: string;
  label?: string;
  isVisible?: boolean;
}

export const SiegeSocialSuggestSelect: React.FunctionComponent<
  ISiegeSocialSuggestSelectProps
> = ({ idName, denominationName, bceName, label, isVisible }) => {
  const {
    t,
    apis: { referentials },
  } = useCommonHooks();
  const { formik } = useFGContext();

  const [textMode, setTextMode] = React.useState(false);

  const fetchItems = React.useCallback(
    async () => await referentials.siegesSociauxSuggest(),
    [referentials]
  );
  const { data, isFetching } = useQuery(["siege-social-suggest"], fetchItems);

  if (!isVisible) return <></>;

  return textMode ? (
    <FlexFieldGroup>
      <FGTextInput
        name={denominationName}
        label={label ?? t(ETLCodes.SiegeSocial)}
        rightElement={
          <SwitchButton
            icon="undo"
            className=""
            onClick={() => {
              formik.setFieldValue(denominationName, undefined);
              formik.setFieldValue(bceName, undefined);
              setTextMode(false);
            }}
          />
        }
      />
      <FGMaskInput
        name="numeroBce"
        label={t(ETLCodes.NumeroBce)}
        cleaveOptions={{
          blocks: [4, 3, 3],
          delimiter: ".",
        }}
      />
    </FlexFieldGroup>
  ) : (
    <FGSelectInput
      name={idName}
      label={label ?? t(ETLCodes.SiegeSocial)}
      items={data}
      loading={isFetching}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      rightElement={
        <SwitchButton
          icon="add"
          smaller
          onClick={() => {
            formik.setFieldValue(idName, undefined);
            setTextMode(true);
          }}
        />
      }
    />
  );
};

export default SiegeSocialSuggestSelect;
