import { FGSelectInput, IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { ReferentialItemDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";

export interface ICountriesSelectProps
  extends Omit<
    IFGSelectInputProps<any>,
    "items" | "loading" | "displayField" | "valueField"
  > {}

export const CountriesSelect: React.FunctionComponent<
  ICountriesSelectProps
> = ({ ...fgSelectInputProps }) => {
  const { t, apis } = useCommonHooks();

  const { data, isFetching } = useQuery("ref-countries", async () => {
    return await apis.referentials.countries();
  });

  return (
    <FGSelectInput
      label={fgSelectInputProps.label || t(ETLCodes.Pays)}
      items={data}
      loading={isFetching}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      {...fgSelectInputProps}
    />
  );
};
