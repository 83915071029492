import { Classes, Colors, Spinner } from "@blueprintjs/core";
import { darken } from "polished";
import * as React from "react";
import { useQuery } from "react-query";
import seedrandom from "seedrandom";
import styled from "styled-components";

import { useCommonHooks } from "../hooks";

const IconStyled = styled.div<{
  color: string;
  size: number;
  fontSize: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  font-weight: 500;
  font-size: ${(props) => props.fontSize}px;
  background-color: ${(props) => props.color};
  & > span {
    color: ${(props) => darken(0.5, props.color)} !important;
  }
`;

const StyledImg = styled.img`
  max-height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

export interface IUserIconProps {
  initials?: string;
  size?: number;
  fontSize?: number;
  azureId?: string;
  placeholderMode?: boolean;
}

export const UserIcon: React.FunctionComponent<IUserIconProps> = ({
  initials,
  size = 30,
  fontSize = 12,
  azureId,
  placeholderMode = false,
}) => {
  const {
    user,
    userColor,
    initials: currentUserInitials,
    apis,
  } = useCommonHooks();

  const randomColor = React.useMemo(() => {
    if (placeholderMode) return Colors.LIGHT_GRAY3;
    if (!initials) return null;
    const generator = seedrandom(initials);
    const h = generator();
    return `hsl(${Math.floor(360 * h)}deg, ${Math.floor(
      25 + 70 * h
    )}%, ${Math.floor(85 + 10 * h)}%)`;
  }, [initials, placeholderMode]);

  const { data: photo, isFetching } = useQuery(
    ["user-profile-photo", azureId],
    async () => !!azureId && (await apis.user.azureProfilePicture(azureId))
  );

  return !!photo ? (
    <StyledImg src={`data:image/png;base64,${photo}`} alt="Profil" />
  ) : (
    user && (
      <IconStyled
        color={randomColor ?? userColor}
        size={size}
        fontSize={fontSize}
        className={placeholderMode && Classes.SKELETON}
      >
        {isFetching ? (
          <Spinner size={20} />
        ) : (
          <span>{placeholderMode ? "" : initials ?? currentUserInitials}</span>
        )}
      </IconStyled>
    )
  );
};

export default UserIcon;
