import { Menu } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

import { InboxRowDto, InboxStep } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import InboxMenuDateCollapse from "./InboxMenuDateCollapse";

export enum InboxMenuRowGroup {
  Today = "Today",
  Yesterday = "Yesterday",
  LastWeek = "LastWeek",
  TwoWeeksAgo = "TwoWeeksAgo",
  ThreeWeeksAgo = "ThreeWeeksAgo",
  LastMonth = "LastMonth",
  LastYear = "LastYear",
  Older = "Older",
}

export interface IInboxMenuRow {
  title: string;
  description: string;
  date: Date;
  onClick?: () => void;
  items?: IInboxMenuRow[];
}

export interface IInboxMenuProps {
  onRowClick: (row: InboxRowDto) => void;
  archived?: boolean;
}

const StyledMenu = styled(Menu)`
  flex: 1;
  padding: 0 !important;
  max-width: 50%;

  @media (max-width: 1200px) {
    max-width: 34%;
  }
`;

export const InboxMenu: React.FunctionComponent<IInboxMenuProps> = ({
  onRowClick,
  archived = false,
}) => {
  const { t } = useCommonHooks();

  return (
    <StyledMenu>
      <InboxMenuDateCollapse
        title={t(ETLCodes.Today)}
        step={InboxStep.Today}
        onRowClick={onRowClick}
        defaultOpen
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.Yesterday)}
        step={InboxStep.Yesterday}
        onRowClick={onRowClick}
        defaultOpen
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.LastWeek)}
        step={InboxStep.LastWeek}
        onRowClick={onRowClick}
        defaultOpen
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.TwoWeeksAgo)}
        step={InboxStep.TwoWeeksAgo}
        onRowClick={onRowClick}
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.ThreeWeeksAgo)}
        step={InboxStep.ThreeWeeksAgo}
        onRowClick={onRowClick}
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.LastMonth)}
        step={InboxStep.LastMonth}
        onRowClick={onRowClick}
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.LastYear)}
        step={InboxStep.LastYear}
        onRowClick={onRowClick}
        archived={archived}
      />
      <InboxMenuDateCollapse
        title={t(ETLCodes.Older)}
        step={InboxStep.Older}
        onRowClick={onRowClick}
        archived={archived}
      />
    </StyledMenu>
  );
};

export default InboxMenu;
