import * as React from "react";
import { Alignment, Classes, Colors, Navbar, Spinner } from "@blueprintjs/core";
import styled from "styled-components";
import { IThemeData } from "../theme";
import { useCommonHooks } from "../hooks";

const StyledNavbar = styled(Navbar)<{ theme: IThemeData }>`
  height: 25px;
  background-color: ${(props) =>
    props.theme.headerColor || Colors.DARK_GRAY4} !important;
`;

const StyledNavbarGroup = styled(Navbar.Group)`
  height: 25px;
`;

const StyledNavbarHeading = styled(Navbar.Heading)`
  font-size: 12px;
`;

export interface IFooterProps {
  version?: string;
  versionLoading?: boolean;
  copyRight?: string;
}

export const Footer: React.FC<IFooterProps> = ({
  version,
  versionLoading = false,
  copyRight = `Powered by NSI-SA ${new Date().getFullYear()}`,
}) => {
  const { theme } = useCommonHooks();

  return (
    <StyledNavbar className={Classes.DARK} theme={theme}>
      <StyledNavbarGroup align={Alignment.LEFT}>
        <StyledNavbarHeading>{copyRight}</StyledNavbarHeading>
      </StyledNavbarGroup>
      <StyledNavbarGroup align={Alignment.RIGHT}>
        <StyledNavbarHeading>
          {versionLoading ? <Spinner size={16} /> : version}
        </StyledNavbarHeading>
      </StyledNavbarGroup>
    </StyledNavbar>
  );
};
