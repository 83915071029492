import React from "react";

export function useScrollWithShadow() {
  const [scrollTop, setScrollTop] = React.useState(0);
  const [scrollHeight, setScrollHeight] = React.useState(0);
  const [clientHeight, setClientHeight] = React.useState(0);

  const onScrollHandler = (event) => {
    setScrollTop(event.target.scrollTop);
    setScrollHeight(event.target.scrollHeight);
    setClientHeight(event.target.clientHeight);
  };

  function getBoxShadow() {
    const isBottom = clientHeight === scrollHeight - scrollTop;
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

    let boxShadow = "none";
    const top = "0 8px 5px -5px rgb(200 200 200 / 0.5)";

    if ((isBottom || isBetween) && !isTop) {
      boxShadow = top;
    }
    return boxShadow;
  }

  return { boxShadow: getBoxShadow(), onScrollHandler };
}
