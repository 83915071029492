import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ISaveButtonProps extends ICustomButtonProps {}

export const SaveButton: React.FunctionComponent<ISaveButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text,
  style,
  className,
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.FLOPPY_DISK}
      onClick={onClick}
      text={minimal ? null : text ? text : t(ETLCodes.Save)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      type={onClick ? "button" : "submit"}
      style={style}
      className={className}
    />
  );
};
