import { FGSelectInput, IFGSelectInputProps } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import { ReferentialItemDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";

export interface IFormeSelectProps
  extends Omit<
    IFGSelectInputProps<any>,
    "items" | "loading" | "displayField" | "valueField"
  > {}

export const FormeSelect: React.FunctionComponent<IFormeSelectProps> = ({
  ...fgSelectInputProps
}) => {
  const { t, apis, tUnsafe } = useCommonHooks();

  const { data, isFetching } = useQuery("ref-formes", async () => {
    const values = await apis.referentials.formes();
    return values.map((d) => ({ ...d, displayValue: tUnsafe(d.displayValue) }));
  });

  return (
    <FGSelectInput
      label={fgSelectInputProps.label || t(ETLCodes.Type)}
      items={data}
      loading={isFetching}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      {...fgSelectInputProps}
    />
  );
};

export default FormeSelect;
