import { getIn } from "formik";
import { FGTextInput, useFGContext, usePrevious } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";

export interface IFGPersonneCodePostalProps {
  idName: string;
  textName: string;
  localiteName?: string;
  disabled?: boolean;
  visible?: boolean;
}

export const FGPersonneCodePostal: React.FunctionComponent<
  IFGPersonneCodePostalProps
> = ({ textName, idName, localiteName, disabled, visible }) => {
  const { t, apis } = useCommonHooks();
  const { formik } = useFGContext();

  const { data: codePostaux } = useQuery(
    ["all-codes-postaux"],
    React.useCallback(() => {
      return apis.referentials.postalCodes();
    }, [apis])
  );

  const cpText = React.useMemo(
    () => getIn(formik?.values ?? {}, textName),
    [formik?.values, textName]
  );
  const idCp = React.useMemo(
    () => getIn(formik?.values ?? {}, idName),
    [formik?.values, idName]
  );
  const localite = React.useMemo(
    () => getIn(formik?.values ?? {}, localiteName),
    [formik?.values, localiteName]
  );
  const previousCodePostalText = usePrevious(cpText);
  const previousLocalite = usePrevious(localite);

  React.useEffect(() => {
    if (
      !cpText ||
      (cpText === previousCodePostalText && previousLocalite === localite) ||
      !codePostaux?.length
    )
      return;
    const cpList = codePostaux?.filter((cp) => cp.codePostal === cpText) ?? [];
    const foundIdCp =
      cpList.length === 1
        ? cpList[0].idcodePostal
        : !!localite
        ? cpList.find((cp) => cp.localite === localite)?.idcodePostal
        : cpList.find((cp) => cp.codePays === "BE")?.idcodePostal;
    if (+foundIdCp === +idCp) return;
    formik.setFieldValue(idName, foundIdCp);
  }, [
    codePostaux,
    cpText,
    formik,
    idCp,
    idName,
    localite,
    previousCodePostalText,
    previousLocalite,
  ]);

  return (
    <FGTextInput
      labelStyles={{ minWidth: 100 }}
      name={textName}
      label={t(ETLCodes.CodePostal)}
      maxLength={20}
      disabled={disabled}
      visible={visible}
    />
  );
};
