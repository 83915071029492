import {
  AccountInfo,
  AuthenticationResult,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useLocalStorageState, useSessionStorageState } from "ahooks";
import * as React from "react";
import { useLocation } from "react-router";
import seedrandom from "seedrandom";

import { ActionCode, UserApi, UserProfileDto } from "../api";
import { baseUrl } from "../apiConfig";
import { useFrontendSettings } from "../hooks/applicationHooks/useFrontendSettings";

interface IAuthProviderProps {
  children: React.ReactNode;
}

export interface ErrorDetails {
  statusCode?: number;
  message?: string | null;
}

const AuthContext = React.createContext<IAuthContext>(null);

export const AuthProvider: React.FunctionComponent<IAuthProviderProps> = ({
  children,
}) => {
  const [logoutReason, setLogoutReason] = React.useState<string>();
  const [user, setUser] = React.useState<UserProfileDto>();
  const isLoggedIn = React.useMemo(() => !!user, [user]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loginError, setLoginError] = React.useState();
  const { frontendSettings } = useFrontendSettings();

  const msalInstance = React.useMemo(() => {
    if (frontendSettings) {
      return new PublicClientApplication({
        auth: {
          clientId: frontendSettings.azureClientId,
          authority: frontendSettings.azureAuthority,
        },
        cache: {
          cacheLocation: "localStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
          loggerOptions: {
            loggerCallback: (logLevel, message) => {
              // eslint-disable-next-line no-console
              console.log("[MSAL]", message);
            },
            piiLoggingEnabled: true,
            logLevel: LogLevel.Warning,
          },
        },
      });
    }
  }, [frontendSettings]);

  const [azureAdAccount, setAzureAdAccount] = React.useState<AccountInfo>();

  const [, setRedirectUri] = useSessionStorageState<string>("redirect_uri", {
    onError: console.log,
  });
  const [lastAccountName, setLastAccountName] = useLocalStorageState<string>(
    "account_name",
    { onError: console.log }
  );

  const location = useLocation();

  const logout = React.useCallback(
    async (reason?: string) => {
      setLogoutReason(reason);
      await msalInstance.logoutRedirect({
        account: azureAdAccount,
      });
      setUser(null);
    },
    [azureAdAccount, msalInstance]
  );

  const loginProcess = React.useCallback(() => {
    const previousPath = location.pathname;
    return msalInstance
      .handleRedirectPromise()
      .then((redirectResponse) => {
        if (redirectResponse !== null) {
          setAzureAdAccount(redirectResponse.account);
          setLastAccountName(redirectResponse.account.username);
        } else {
          setRedirectUri(previousPath);
          const account = msalInstance.getAccountByUsername(lastAccountName);
          if (account) {
            console.log("Local account found ...", account);

            setAzureAdAccount(account);
          } else {
            console.log("No account found, login with azure ad ! ");

            msalInstance.loginRedirect({
              scopes: [frontendSettings?.azureApiScope],
              loginHint: lastAccountName,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [
    lastAccountName,
    frontendSettings,
    location.pathname,
    msalInstance,
    setLastAccountName,
    setRedirectUri,
  ]);

  React.useEffect(() => {
    if (!isLoggedIn && msalInstance) {
      loginProcess();
    }
  }, [isLoggedIn, loginProcess, msalInstance]);

  const getAccessToken = React.useCallback(async () => {
    let refreshedToken: AuthenticationResult;
    try {
      if (azureAdAccount) {
        refreshedToken = await msalInstance.acquireTokenSilent({
          account: azureAdAccount,
          scopes: [frontendSettings.azureApiScope],
        });
        return "Bearer " + refreshedToken.accessToken;
      }
    } catch (error) {
      console.error("Unable to get token silently", error);
      await msalInstance.loginRedirect({
        scopes: [frontendSettings?.azureApiScope],
        loginHint: lastAccountName,
      });
    }

    return null;
  }, [azureAdAccount, lastAccountName, frontendSettings, msalInstance]);

  const loadUser = React.useCallback(() => {
    const api = new UserApi({
      basePath: baseUrl,
      middlewares: [],
      apiKeyPromise: getAccessToken,
    });

    api
      .profile()
      .then((user) => {
        setUser(user);
        setIsLoading(false);
      })
      .catch((error) => {
        setLoginError(error);
        setIsLoading(false);
      });
  }, [getAccessToken]);

  React.useEffect(() => {
    if (azureAdAccount) {
      console.log("Azure ad account ready, getting user profile...");
      loadUser();
    }
  }, [azureAdAccount, loadUser]);

  const hasAction = React.useCallback(
    (actionList: ActionCode[] = []) => {
      if (actionList.length === 0) return true;
      return actionList.filter((v) => user?.actions.includes(v)).length > 0;
    },
    [user?.actions]
  );

  // const isCreator = React.useCallback(
  //   <T extends ICreatedByType>(actionList: ActionCode[], data: T) =>
  //     hasAction(actionList) && user?.userId === data?.createdBy,
  //   [hasAction, user]
  // );

  const fullUserName = React.useMemo(
    () => azureAdAccount?.name,
    [azureAdAccount?.name]
  );

  const initials = React.useMemo(
    () => user && user.firstName[0] + user.lastName[0],
    [user]
  );

  const userColor = React.useMemo(() => {
    if (!initials) return "#ffffff";
    const generator = seedrandom(initials);
    const h = generator();
    return `hsl(${Math.floor(360 * h)}deg, ${Math.floor(
      25 + 70 * h
    )}%, ${Math.floor(85 + 10 * h)}%)`;
  }, [initials]);

  return (
    <AuthContext.Provider
      value={{
        error: loginError,
        hasAction,
        // isCreator,
        isLoading,
        logout,
        logoutReason,
        user,
        getAccessToken,
        isLoggedIn,
        fullUserName,
        initials,
        userColor,
        reloadUser: loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

interface IAuthContext {
  logout: () => void;
  isLoading: boolean;
  user?: UserProfileDto;
  error?: ErrorDetails;
  hasAction: (actionList: ActionCode[]) => boolean;
  logoutReason: string;
  // isCreator: <T extends ICreatedByType>(
  //   actionList: ActionCode[],
  //   data: T
  // ) => boolean;
  getAccessToken: () => Promise<string>;
  isLoggedIn: boolean;
  fullUserName: string;
  initials: string;
  userColor: string;
  reloadUser: () => void;
}

export const useAuth = () => React.useContext(AuthContext);
