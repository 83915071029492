import {
  Classes,
  Collapse,
  Colors,
  Dialog,
  Elevation,
} from "@blueprintjs/core";
import classNames from "classnames";
import sortBy from "lodash.sortby";
import {
  BaseFilterCriteriaInfo,
  DataTable2,
  IDataTableColumn,
  IDataTableProps,
  ISearchExpressionPanelProps,
  IUseGridStateOptions,
  SearchExpressionPanel,
  SearchQuery,
  showError,
  showSuccess,
  SortKeysObject,
  useGridState,
  useSearchExpressionApi,
} from "nsitools-react";
import * as React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

import { CanDoAction, Dropzone, DropzoneFile, ExportButton } from "../";
import { ActionCode, FileResponse, PaginatedSearchable } from "../../api";
import {
  useEventsContext,
  useHopeViewVerticalTabsContext,
} from "../../contexts";
import { useBrowserStorage, useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { IThemeData } from "../../theme";
import { nameof } from "../../utils";
import { downloadFileResponse } from "../../utils/downloadFileResponse";
import { formatDate } from "../../utils/formatDate";
import {
  DownloadButton,
  ImportButton,
  SaveButton,
} from "../applicationButtons";
import { CardFieldGroup, CardFieldGroupProps } from "../formGenerator";
import AdvancedSearchTableDrawerWrapper from "./AdvancedSearchTableDrawerWrapper";

const DialogBody = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.5rem;
  }
`;

export interface ICustomBooleanTranslation {
  criteria: string;
  noCode: string;
  yesCode: string;
}

export interface ISearchTableProps<
  TSearch extends PaginatedSearchable,
  TResults
> extends Pick<
      IDataTableProps<any>,
      "customizeRowStyle" | "onRowClick" | "onOpenSubComponent"
    >,
    Pick<CardFieldGroupProps, "title" | "rightElement" | "leftElement">,
    Pick<
      ISearchExpressionPanelProps<any>,
      | "overrideListValues"
      | "defaultSelectedCriteria"
      | "storageMode"
      | "advancedPanelOptions"
      | "advancedModeDisabled"
      | "classicPanelOptions"
    >,
    Pick<
      IUseGridStateOptions<any>,
      "enableFilter" | "enableMultiSort" | "enablePagination"
    > {
  pageSize?: number;
  availablePageSizes?: number[];
  searchFunction: (
    searchQuery: SearchQuery<TSearch>
  ) => TResults | Promise<TResults>;
  exportFunction?: (searchQuery: SearchQuery<TSearch>) => Promise<FileResponse>;
  importFunction?: ({ fileName, data }) => void;
  getTemplateFunction?: () => Promise<FileResponse>;
  canDoExport?: ActionCode[];
  getCriteriasFunction?: () => Promise<BaseFilterCriteriaInfo[]>;
  columns: Array<IDataTableColumn>;
  criteriasTlPrefix?: string;
  sortKeys?: SortKeysObject;
  addButtonOnClick?: () => void;
  onOpenSubComponent?: (item: any) => React.ReactElement | string;
  defaultPageSize?: number;
  children?: (searchFn: Function) => React.ReactElement;
  showColumnSelector?: boolean;
  headerWrap?: boolean;
  withCards?: boolean;
  focusOnFilter?: boolean;
  highlightFilter?: boolean;
  storageKeySuffix?: string;
  onToggleSelectAll?: () => Set<any> | Promise<Set<any>>;
  enableSelection?: boolean;
  keyFieldAccessor?: (item: any) => any;
  onSelectedKeysChange?: (selectedKeys: any[]) => void;
  multipleEditPanel?: React.ReactNode;
  isEditCollapseOpen?: boolean;
  drawerComponent?: React.ReactNode;
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
  showTitleBorderBottom?: boolean;
  largeTitle?: boolean;
  cardElevation?: Elevation;
  customBooleanTranslations?: ICustomBooleanTranslation[];
  duplicateElements?: boolean;
  sortCriterias?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .${Classes.OVERLAY}:has(> .inner-hope-view) {
    width: 100% !important;
  }

  & .${Classes.OVERLAY} {
    width: 42%;
    left: auto !important;

    @media (max-width: 1200px) {
      width: 58%;
    }
  }

  & .${Classes.DRAWER} {
    background: #f6f7f9 !important;
  }
`;

const StyledDataTable2 = styled(DataTable2)<{ theme: IThemeData }>`
  th {
    font-weight: 500 !important;
    color: ${Colors.DARK_GRAY5} !important;
    vertical-align: middle !important;
  }

  &.header-wrap th {
    white-space: break-spaces !important;
  }

  td {
    //font-weight: 500 !important;
  }

  &
    table.${Classes.HTML_TABLE}.${Classes.HTML_TABLE_STRIPED}
    tbody
    tr:nth-child(odd) {
    background: ${(props) => props.theme.tableStrip};
  }

  & table.${Classes.HTML_TABLE}.${Classes.INTERACTIVE} tbody tr:hover {
    /* background-color: ${(props) => props.theme.tableRowHover} !important; */
  }
`;

const StyledCardFieldGroup = styled(CardFieldGroup)`
  & .${Classes.COLLAPSE} {
    flex: 1;
  }

  & .flex-col,
  .datatable {
    width: 100%;
  }
`;

const ElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export function AdvancedSearchTable<
  TSearch extends PaginatedSearchable,
  TResults
>({
  searchFunction,
  exportFunction,
  importFunction,
  getTemplateFunction,
  canDoExport,
  getCriteriasFunction,
  columns,
  criteriasTlPrefix,
  overrideListValues,
  sortKeys = {},
  rightElement,
  customizeRowStyle,
  title,
  onRowClick,
  enablePagination = true,
  enableFilter = true,
  enableMultiSort = false,
  pageSize = 15,
  availablePageSizes = [15, 25, 50],
  storageMode = "sessionStorage",
  storageKeySuffix,
  children,
  showColumnSelector = true,
  headerWrap = false,
  withCards = true,
  focusOnFilter = false,
  highlightFilter = false,
  advancedPanelOptions = {},
  classicPanelOptions = {},
  advancedModeDisabled = true,
  onToggleSelectAll,
  enableSelection,
  keyFieldAccessor,
  onSelectedKeysChange = () => {},
  multipleEditPanel,
  isEditCollapseOpen = false,
  drawerComponent,
  isDrawerOpen = false,
  onDrawerClose,
  showTitleBorderBottom = true,
  largeTitle = false,
  cardElevation,
  customBooleanTranslations,
  leftElement,
  duplicateElements,
  sortCriterias = true,
}: ISearchTableProps<TSearch, TResults>) {
  const { router, currentLanguage, theme, t, tUnsafe, notifyGlobalError } =
    useCommonHooks();
  const location = useLocation();
  const { clear } = useHopeViewVerticalTabsContext();

  const [importDialogOpened, setImportDialogOpened] = React.useState<boolean>();
  const [uploadedFiles, setUploadedFile] = React.useState<DropzoneFile[]>([]);
  const [isImporting, setIsImporting] = React.useState(false);
  const [isGettingImportTemplate, setIsGettingImportTemplate] =
    React.useState(false);

  const getTemplateComputedFunction = React.useCallback(async () => {
    setIsGettingImportTemplate(true);
    try {
      const res = await getTemplateFunction();
      await downloadFileResponse(res);
    } catch (e) {
      console.error(e);
      showError(t(ETLCodes.Error));
    } finally {
      setIsGettingImportTemplate(false);
    }
  }, [getTemplateFunction, t]);

  const importComputedFunction = React.useCallback(async () => {
    setIsImporting(true);
    try {
      setIsImporting(true);
      if (uploadedFiles.length > 0) {
        await importFunction({
          data: uploadedFiles[0].file,
          fileName: uploadedFiles[0].name,
        });
      }
      setImportDialogOpened(false);
      showSuccess(t(ETLCodes.ImportSuccess));
    } catch (err) {
      notifyGlobalError(err, "", true);
    } finally {
      setIsImporting(false);
    }
  }, [importFunction, notifyGlobalError, t, uploadedFiles]);

  const { data: savedFilterSearch, set: setSavedFilterSearch } =
    useBrowserStorage(
      "grid_filter_" + location.pathname,
      storageMode === "localeStorage"
        ? "localeStorage"
        : storageMode === "sessionStorage"
        ? "sessionStorage"
        : "none"
    );

  const tableState = useGridState<any>({
    serverMode: true,
    enablePagination,
    enableFilter,
    enableMultiSort,
    availablePageSizes,
    pageSize,
    sortKeys: sortKeys,
    globalFilter: savedFilterSearch,
    storageOptions: {
      storageKey:
        "datagrid_" +
        router.pathname +
        (storageKeySuffix ? `_${storageKeySuffix}` : ""),
      columnCustomizationStorageMode: "sessionStorage",
      globalFilterStorageMode: "sessionStorage",
      pageIndexStorageMode: "sessionStorage",
      pageSizeStorageMode: "sessionStorage",
      sortKeysStorageMode: "state",
    },
    enableSelection,
    keyFieldAccessor,
  });
  const { totalCount, selectedKeys } = tableState;
  React.useEffect(() => {
    if (selectedKeys) {
      onSelectedKeysChange([...selectedKeys]);
    }
  }, [onSelectedKeysChange, selectedKeys]);

  const innerSearchFunction = React.useCallback(
    (searchQuery: SearchQuery<TSearch>) => {
      if (searchQuery) {
        setSavedFilterSearch(searchQuery.criteria?.filter);
      }
      return searchFunction(searchQuery);
    },
    [searchFunction, setSavedFilterSearch]
  );

  const { search, loading, searchData } = useSearchExpressionApi({
    searchFunction: innerSearchFunction,
    tableState,
    initialSearch: !!!getCriteriasFunction,
    trimSearchObjectStringValues: true,
  });

  const OnRefreshSearchTablesEvent = React.useCallback(() => {
    search();
  }, [search]);

  const { subscribeToEvent, unsubscribeEvent } = useEventsContext();
  React.useEffect(() => {
    subscribeToEvent("RefreshSearchTables", OnRefreshSearchTablesEvent);
    return () =>
      unsubscribeEvent("RefreshSearchTables", OnRefreshSearchTablesEvent);
  }, [OnRefreshSearchTablesEvent, subscribeToEvent, unsubscribeEvent]);

  const finalSearchCriteriaFunction = React.useMemo(() => {
    if (getCriteriasFunction && currentLanguage) {
      return async () => {
        let criteriasFunction = await getCriteriasFunction();
        return sortCriterias
          ? sortBy(
              criteriasFunction,
              nameof<BaseFilterCriteriaInfo>("criteria")
            )
          : criteriasFunction;
      };
    }
  }, [currentLanguage, getCriteriasFunction, sortCriterias]);

  const [exporting, setExporting] = React.useState(false);
  const exportGridData = React.useCallback(async () => {
    setExporting(true);
    try {
      const file = await exportFunction(searchData);
      await downloadFileResponse(file);
    } catch (e) {
      console.error(e);
      showError(t(ETLCodes.Error));
    }
    setExporting(false);
  }, [searchData, exportFunction, t]);

  const constructedLeftElement = React.useMemo(() => {
    return (
      <>
        {leftElement}
        {exportFunction && (
          <CanDoAction actions={canDoExport}>
            <ExportButton onClick={exportGridData} loading={exporting} />
          </CanDoAction>
        )}
        {importFunction && (
          <CanDoAction actions={canDoExport}>
            <ImportButton onClick={() => setImportDialogOpened(true)} />
          </CanDoAction>
        )}
      </>
    );
  }, [
    canDoExport,
    exportFunction,
    exportGridData,
    exporting,
    importFunction,
    leftElement,
  ]);

  const finalColumns = React.useMemo(
    () => columns?.map((c) => ({ ...c, textEllipsisAfterWidth: "50px" })),
    [columns]
  );

  React.useEffect(() => {
    const handleOutSideClick = (event) => {
      if (
        !event.target.closest(`.${Classes.OVERLAY}`) &&
        !event.target.closest("tr") &&
        !event.target.closest(".v-tab") &&
        !!onDrawerClose
      ) {
        onDrawerClose();
        clear();
      }
    };

    window.addEventListener("mousedown", handleOutSideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [clear, onDrawerClose]);

  return (
    <Container>
      {getCriteriasFunction && (
        <CardFieldGroup title={t(ETLCodes.TableCriterias)} withCard={withCards}>
          <SearchExpressionPanel
            getCriteriasFunc={finalSearchCriteriaFunction}
            onSearch={search}
            translateFunc={tUnsafe}
            tlDataPrefix={criteriasTlPrefix}
            overrideListValues={overrideListValues}
            triggerInitialSearch={true}
            criteriaIntent="success"
            storageMode={storageMode}
            storageKey={"search_" + location.pathname}
            advancedPanelOptions={{
              ...advancedPanelOptions,
              viewerProps: {
                inlineCondition: true,
                ...advancedPanelOptions.viewerProps,
              },
            }}
            searchMode="auto"
            advancedModeDisabled={advancedModeDisabled}
            classicPanelOptions={classicPanelOptions}
            showRazButton={true}
            customBooleanTranslations={customBooleanTranslations}
          />
        </CardFieldGroup>
      )}
      <StyledCardFieldGroup
        rightTitle={
          title ? title : t(ETLCodes.TableResults, { count: totalCount })
        }
        rightElement={rightElement}
        leftElement={constructedLeftElement}
        withCard={withCards}
        elevation={cardElevation}
        showBorderBottom={showTitleBorderBottom}
        largeTitle={largeTitle}
      >
        {!!multipleEditPanel && (
          <Collapse isOpen={isEditCollapseOpen}>{multipleEditPanel}</Collapse>
        )}
        <StyledDataTable2
          theme={theme}
          tableState={tableState}
          loading={loading}
          columns={finalColumns}
          showColumnSelector={showColumnSelector}
          customizeRowStyle={customizeRowStyle}
          onRowClick={onRowClick}
          filterMode="OnEnter"
          translateFunc={tUnsafe}
          formatDate={formatDate}
          className={classNames({ "header-wrap": headerWrap, datatable: true })}
          focusOnFilter={focusOnFilter}
          highlightFilter={highlightFilter}
          onToggleSelectAll={onToggleSelectAll}
          htmlTableOptions={{
            bordered: false,
            condensed: false,
            striped: true,
            interactive: !!onRowClick,
          }}
        />
        {duplicateElements && (leftElement || rightElement) && (
          <ElementsContainer>
            {leftElement}
            {rightElement}
          </ElementsContainer>
        )}
      </StyledCardFieldGroup>
      {children ? children(search) : null}

      <Dialog
        isOpen={importDialogOpened}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        title={t(ETLCodes.Import)}
        icon={"import"}
        onClose={() => {
          setImportDialogOpened(false);
        }}
      >
        <DialogBody>
          <Dropzone onFilesAdded={(f) => setUploadedFile(f)} />
          {!!getTemplateFunction ? (
            <DownloadButton
              text={t(ETLCodes.GetTemplate)}
              onClick={getTemplateComputedFunction}
              loading={isGettingImportTemplate}
            />
          ) : null}
          <SaveButton
            onClick={importComputedFunction}
            loading={isImporting}
            minimal={false}
            disabled={uploadedFiles.length === 0}
          />
        </DialogBody>
      </Dialog>
      {drawerComponent && (
        <AdvancedSearchTableDrawerWrapper
          hasBackdrop={false}
          usePortal={false}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          canOutsideClickClose={false}
          canEscapeKeyClose={false}
          enforceFocus={false}
          size="100%"
        >
          {drawerComponent}
        </AdvancedSearchTableDrawerWrapper>
      )}
    </Container>
  );
}
