import { Card, Checkbox, Classes, Colors } from "@blueprintjs/core";
import { FormikProps, getIn } from "formik";
import {
  IDataTableColumn,
  InlineButtonContainer,
  SearchQuery,
  useFGContext,
} from "nsitools-react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import {
  SearchQueryOfUserSearchCriteria,
  UserApi,
  UserGridDto,
  UserSearchCriteria,
} from "../../../api";
import { useApiService, useCommonHooks } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { CancelButton, ValidateButton } from "../../applicationButtons";
import { DataTableBooleanColumn } from "../../customDataTable";
import { AdvancedSearchTable } from "../../searchTable/AdvancedSearchTable";

const CustomButtonContainer = styled(InlineButtonContainer)`
  display: flex;
  justify-content: space-between;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;

  & > .${Classes.CHECKBOX} {
    font-weight: 500;
  }
`;

export interface IUserSelectorProps {
  onRowClick?: (user: UserGridDto) => void;
  onClose?: () => void;
  allowMultipleSelection?: boolean;
  preSelectedIds?: number[];
  name: string;
  idField: string;
  formikInnerRef?: React.MutableRefObject<FormikProps<any>>;
  panelHeight?: string;
  defaultCriterias?: UserSearchCriteria;
}

export const UserSelector: React.FunctionComponent<IUserSelectorProps> = ({
  name,
  idField,
  allowMultipleSelection,
  onClose = () => {},
  onRowClick = () => {},
  formikInnerRef,
  panelHeight,
  defaultCriterias,
}) => {
  const { t } = useCommonHooks();
  const userApi = useApiService(UserApi);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [activeOnly, setActiveOnly] = React.useState(true);

  const ctx = useFGContext();
  const formik = React.useMemo(
    () => ctx?.formik ?? formikInnerRef?.current,
    [ctx?.formik, formikInnerRef]
  );

  React.useEffect(() => {
    const value = getIn(formik?.values ?? {}, name);
    if (selectedIds.length === 0 && value?.length > 0) {
      setSelectedIds(value);
    }
  }, [formik?.values, name, selectedIds.length]);

  const search = useCallback(
    (searchQuery: SearchQuery<UserSearchCriteria>) => {
      if (!!defaultCriterias)
        searchQuery.criteria = UserSearchCriteria.fromJS({
          ...defaultCriterias,
          ...searchQuery.criteria,
        });
      searchQuery.criteria.activeOnly = activeOnly;
      return userApi.baseSearch(
        SearchQueryOfUserSearchCriteria.fromJS(searchQuery)
      );
    },
    [activeOnly, defaultCriterias, userApi]
  );

  const columns = useMemo<IDataTableColumn<UserGridDto>[]>(
    () => [
      {
        fieldName: "firstName",
        header: () => t(ETLCodes.FirstName),
      },
      {
        fieldName: "lastName",
        header: () => t(ETLCodes.LastName),
      },
      {
        fieldName: "role",
        header: () => t(ETLCodes.Role),
      },
      {
        fieldName: "isActive",
        header: () => t(ETLCodes.IsActive),
        alignment: "center",
        render: (item) => (
          <DataTableBooleanColumn
            checked={item.isActive}
            highlighted={selectedIds.includes(item.iduser)}
          />
        ),
      },
    ],
    [selectedIds, t]
  );

  const addId = React.useCallback(
    (id: number) => setSelectedIds((prev) => [...prev, id]),
    []
  );

  const removeId = React.useCallback((id: number) => {
    setSelectedIds((prev) => {
      let newValues = [...prev];
      newValues.splice(newValues.indexOf(id), 1);
      return newValues;
    });
  }, []);

  const handleItemClick = React.useCallback(
    (itemId: number) => {
      if (selectedIds?.indexOf(itemId) >= 0) {
        removeId(itemId);
      } else {
        addId(itemId);
      }
    },
    [addId, removeId, selectedIds]
  );

  const onRowClickFn = React.useCallback(
    (item: any) => {
      const itemId = getIn(item, idField);

      if (allowMultipleSelection) {
        handleItemClick(itemId);
        onRowClick(item);
      } else {
        formik.setFieldValue(name, itemId);
        onRowClick(item);
        onClose();
      }
    },
    [
      allowMultipleSelection,
      formik,
      handleItemClick,
      idField,
      name,
      onClose,
      onRowClick,
    ]
  );

  const selectedRowCss = React.useCallback(
    (item: any) => {
      if (
        allowMultipleSelection &&
        selectedIds?.indexOf(getIn(item, idField)) >= 0
      ) {
        return css`
          background: ${Colors.BLUE3} !important;
          & > td {
            color: ${Colors.WHITE} !important;
            font-weight: 500;
          }
        `;
      }
    },
    [allowMultipleSelection, idField, selectedIds]
  );

  const onValidate = React.useCallback(() => {
    formik.setFieldTouched(name);
    formik.setFieldValue(name, selectedIds, true);
    onClose();
  }, [formik, name, onClose, selectedIds]);

  return (
    <Card style={{ height: panelHeight }} className="user-selector-card">
      <CheckboxContainer>
        <Checkbox
          label={t(ETLCodes.ActifsUniquements)}
          checked={activeOnly}
          onChange={(e) => setActiveOnly(e.currentTarget.checked)}
        />
      </CheckboxContainer>
      <AdvancedSearchTable
        sortKeys={{ lastName: "ASC", firstName: "ASC" }}
        searchFunction={search}
        columns={columns}
        onRowClick={onRowClickFn}
        customizeRowStyle={selectedRowCss}
        withCards={false}
        pageSize={10}
        availablePageSizes={[10]}
        storageMode="none"
      />
      <CustomButtonContainer>
        <CancelButton onClick={onClose} minimal={false} />
        {allowMultipleSelection && (
          <ValidateButton onClick={onValidate} minimal={false} />
        )}
      </CustomButtonContainer>
    </Card>
  );
};

export default UserSelector;
