import { format } from "date-fns";
import { fr as loc } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";

export function getBrowserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function ToUtc(d: Date) {
  return utcToZonedTime(d, getBrowserTimeZone());
}

export function formatDate(d: Date) {
  if (!!!d) return "";
  const tmp = utcToZonedTime(d, getBrowserTimeZone());
  return format(tmp, "dd/MM/yyyy", { locale: loc });
}

export function formatDateWithDayOfWeek(d: Date) {
  const tmp = utcToZonedTime(d, getBrowserTimeZone());
  return format(tmp, "EEEE dd/MM", { locale: loc });
}

export function formatDateWithHour(d: Date) {
  const tmp = utcToZonedTime(d, getBrowserTimeZone());
  return format(tmp, "dd/MM/yyyy HH:mm", { locale: loc });
}
export function formatDateWithTime(d: Date) {
  const tmp = utcToZonedTime(d, getBrowserTimeZone());
  return format(tmp, "dd/MM/yyyy HH:mm:ss", { locale: loc });
}

export function getDateHour(d: Date) {
  const tmp = utcToZonedTime(d, getBrowserTimeZone());
  return format(tmp, "HH:mm", { locale: loc });
}
