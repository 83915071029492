import "./index.css";
import "./locales";

import * as React from "react";
import * as ReactDom from "react-dom";

import { App } from "./App";
import { AppProvider } from "./AppProvider";

// If pdf viewer is enabled
//import { pdfjs } from "react-pdf";
//pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";

ReactDom.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById("root")
);
