import { PanelProps } from "@blueprintjs/core";
import { FormikProps } from "formik";
import {
  FGListen,
  FGMaskInput,
  FGTextAreaInput,
  FGTextInput,
  useFGContext,
} from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { Forme } from "../../../api";
import { useCommonHooks } from "../../../hooks";
import { ETLCodes } from "../../../locales";
import { CancelButton, SaveButton } from "../../applicationButtons";
import { NextButton } from "../../applicationButtons/NextButton";
import { CardFieldGroup, FGCustomDateInput } from "../../formGenerator";
import { FGPersonneCodePostal } from "../../formGenerator/FGPersonneCodePostal";
import {
  CitiesSelect,
  CountriesSelect,
  FormeSelect,
  GenderSelect,
} from "../../selects";
import { NationaliteSelect } from "../../selects/NationaliteSelect";
import SiegeSocialSuggestSelect from '../../selects/SiegeSocialSuggestSelect';
import BeneficiarySelectorResultsPanel from "./BeneficiarySelectorResultsPanel";

const Container = styled.div`
  padding: 1px;
  & > .flex-grid {
    flex: 0 !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export interface IBeneficiarySelectorCiteriasPanelProps {
  isEntreprise: (forme: Forme) => boolean;
  onSelect: (value: number, forme: Forme, idpersonne: number) => void;
  onClose: () => void;
  saving: boolean;
  telephone: string;
}

export const BeneficiarySelectorCiteriasPanel: React.FunctionComponent<
  PanelProps<IBeneficiarySelectorCiteriasPanelProps>
> = ({ isEntreprise, onSelect, onClose, saving, telephone, ...props }) => {
  const { t } = useCommonHooks();
  const ctx = useFGContext();

  const [isBeneficiaryCreation, setIsBeneficiaryCreation] =
    React.useState(false);

  const enableCreationMode = React.useCallback(() => {
    setIsBeneficiaryCreation(true);
    ctx.formik?.setFieldValue("", true);
  }, [ctx.formik]);

  const onNext = React.useCallback(() => {
    props.openPanel({
      renderPanel: BeneficiarySelectorResultsPanel,
      props: {
        onSelect,
        setBeneficiaryCreationMode: enableCreationMode,
      },
    });
  }, [enableCreationMode, onSelect, props]);

  const [autoSelected, setAutoSelected] = React.useState(false);
  React.useEffect(() => {
    if (telephone && !isBeneficiaryCreation && !autoSelected) {
      onNext();
      setAutoSelected(true);
    }
  }, [autoSelected, isBeneficiaryCreation, onNext, telephone]);

  const isSaveAllowed = React.useCallback(
    (formik: FormikProps<any>) =>
      formik.dirty || formik.touched || formik.submitCount > 0,
    []
  );

  return (
    <Container>
      <CardFieldGroup
        title={t(
          isBeneficiaryCreation
            ? ETLCodes.AjouterBeneficiaire
            : ETLCodes.RechercheBeneficiaires
        )}
      >
        <FGListen
          field="forme"
          onChanged={(val, formik) => {
            if (!formik.dirty) return;

            if (
              !val ||
              [
                Forme.Apprenant,
                Forme.Formateur,
                Forme.Representant,
                Forme.Tuteur,
              ].includes(val)
            ) {
              formik.setFieldValue("numeroBce", undefined);
              formik.setFieldValue("denominationSociale", undefined);
            } else {
              formik.setFieldValue("dateNaissance", undefined);
              formik.setFieldValue("codesexe", undefined);
              formik.setFieldValue("nationalite", undefined);
              formik.setFieldValue("registreNational", undefined);
            }
          }}
        />
        {isBeneficiaryCreation && <FormeSelect name="forme" />}
        <FGTextInput
          name="telephone"
          label={t(ETLCodes.Telephone)}
          visible={!isBeneficiaryCreation}
        />
        <FGTextInput name="nom" label={t(ETLCodes.Nom)} forceCase="upper" />
        <FGTextInput name="prenom" label={t(ETLCodes.Prenom)} />
        <FGCustomDateInput
          name="dateNaissance"
          label={t(ETLCodes.DateNaissance)}
          visible={!isEntreprise(ctx?.formik?.values?.forme)}
        />
        <SiegeSocialSuggestSelect
          idName="idsiegeSocial"
          denominationName="denominationSociale"
          bceName="numeroBce"
          label={t(ETLCodes.DenominationSociale)}
          isVisible={isBeneficiaryCreation && isEntreprise(ctx?.formik?.values?.forme)}
        />
        <FGTextInput
          name="telephone"
          label={t(ETLCodes.Telephone)}
          visible={isBeneficiaryCreation}
        />
        <FGTextInput
          name="gsm"
          label={t(ETLCodes.Gsm)}
          visible={isBeneficiaryCreation}
        />
        <FGTextInput
          name="email"
          label={t(ETLCodes.Email)}
          visible={isBeneficiaryCreation}
        />
        <GenderSelect
          name="codesexe"
          visible={
            isBeneficiaryCreation && !isEntreprise(ctx?.formik?.values?.forme)
          }
        />
        <NationaliteSelect
          name="nationalite"
          codeSexeFieldName="codesexe"
          visible={
            isBeneficiaryCreation && !isEntreprise(ctx?.formik?.values?.forme)
          }
        />
        <FGMaskInput
          name="registreNational"
          label={t(ETLCodes.NumeroNational)}
          cleaveOptions={{
            delimiters: [".", ".", "-", "."],
            blocks: [2, 2, 2, 3, 2],
          }}
          visible={
            isBeneficiaryCreation &&
            !isEntreprise(ctx?.formik?.values?.forme) &&
            (!ctx.formik?.values?.nationalite ||
              ctx.formik?.values?.nationalite === "BE")
          }
        />
        <CountriesSelect
          labelStyles={{ minWidth: 100 }}
          name="adresse.pays"
          visible={isBeneficiaryCreation}
        />
        <FGTextAreaInput
          labelStyles={{ minWidth: 100 }}
          name="adresse.adresse"
          label={t(ETLCodes.Adresse)}
          visible={isBeneficiaryCreation}
        />
        <FGPersonneCodePostal
          idName="adresse.idcodePostal"
          textName="adresse.codePostalText"
          localiteName="adresse.localite"
          visible={isBeneficiaryCreation}
        />
        <CitiesSelect
          labelStyles={{ minWidth: 100 }}
          name="adresse.localite"
          countryFieldName="adresse.pays"
          visible={isBeneficiaryCreation}
        />

        <ButtonContainer>
          <CancelButton
            onClick={onClose}
            text={t(ETLCodes.Cancel)}
            minimal={false}
            loading={saving}
          />
          {isBeneficiaryCreation ? (
            <SaveButton
              minimal={false}
              disabled={!isSaveAllowed(ctx?.formik)}
              loading={saving}
            />
          ) : (
            <NextButton
              onClick={onNext}
              disabled={!ctx?.formik?.dirty || !ctx?.formik?.isValid}
            />
          )}
        </ButtonContainer>
      </CardFieldGroup>
    </Container>
  );
};

export default BeneficiarySelectorCiteriasPanel;
