import {
  Collapse,
  Colors,
  Icon,
  IconSize,
  MenuItem,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import styled from "styled-components";

import { InboxRowDto, InboxSearchCriteria, InboxStep } from "../../api";
import { useCommonHooks, useEditRouteParams } from "../../hooks";
import { ETLCodes } from "../../locales";
import InboxMenuItem from "./InboxMenuItem";

export interface IInboxMenuDateCollapseProps {
  title: string;
  step: InboxStep;
  defaultOpen?: boolean;
  onRowClick: (row: InboxRowDto) => void;
  archived?: boolean;
}

const StyledMenuItem = styled(MenuItem)`
  background-color: ${Colors.LIGHT_GRAY3};

  &:hover {
    background-color: ${Colors.LIGHT_GRAY1};
    & .collapse-icon {
      color: ${Colors.BLUE5} !important;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  font-weight: bold;
  color: ${Colors.DARK_GRAY5};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-right: 0.5rem;
  & .collapse-icon {
    color: ${Colors.GRAY1} !important;
  }
`;

const InnerContainer = styled.div`
  padding: 2rem 0;
`;

export const InboxMenuDateCollapse: React.FunctionComponent<
  IInboxMenuDateCollapseProps
> = ({ title, step, onRowClick, defaultOpen = false, archived = false }) => {
  const {
    t,
    user,
    apis: { inbox },
  } = useCommonHooks();
  const { id } = useEditRouteParams();
  const { search } = useLocation();
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  const [idPersonneFromGlobalSearch, setIdPersonneFromGlobalSearch] =
    React.useState(null);

  React.useEffect(() => {
    if (!!search) {
      setIdPersonneFromGlobalSearch(
        new URLSearchParams(search).get("idpersonne")
      );
    }
  }, [search]);

  const { data, isFetching, refetch } = useQuery(
    ["inbox-activity", id ?? user.userId, step, isOpen, archived],
    async () =>
      await inbox.search(
        InboxSearchCriteria.fromJS({
          userId: id ?? user.userId,
          archived: archived,
          step: step,
          idpersonne: idPersonneFromGlobalSearch,
        })
      ),
    { enabled: isOpen }
  );

  const hasNoData = React.useMemo(
    () => !isFetching && data?.length === 0 && isOpen,
    [data?.length, isFetching, isOpen]
  );

  return (
    <>
      <StyledMenuItem
        onClick={() => setIsOpen((prev) => !prev)}
        text={
          <TextContainer>
            <IconContainer>
              <Icon
                icon={isOpen ? "caret-down" : "caret-right"}
                className="collapse-icon"
                size={IconSize.LARGE}
              />
            </IconContainer>
            <span>{title}</span>
          </TextContainer>
        }
      />
      <Collapse isOpen={isOpen}>
        {isFetching ? (
          <InnerContainer>
            <Spinner size={20} />
          </InnerContainer>
        ) : hasNoData ? (
          <InnerContainer>
            <NonIdealState description={t(ETLCodes.AucuneNotification)} />
          </InnerContainer>
        ) : (
          data?.map((row, index) => (
            <InboxMenuItem
              key={index}
              row={row}
              onClick={onRowClick}
              interactive
              refetch={refetch}
              archived={archived}
            />
          ))
        )}
      </Collapse>
    </>
  );
};

export default InboxMenuDateCollapse;
