import {
  Button,
  MenuItemProps,
  Intent,
  Menu,
  MenuItem,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import * as React from "react";
import styled from "styled-components";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";

const PopoverContent = styled.div`
  padding: 0.5rem;
`;

export interface IGlobalActionsButtonProps {
  menuItems: MenuItemProps[];
  text?: string;
  intent?: Intent;
  minimal?: boolean;
  loading?: boolean;
}

export const GlobalActionsButton: React.FunctionComponent<
  IGlobalActionsButtonProps
> = ({
  menuItems,
  text,
  intent = Intent.PRIMARY,
  minimal = true,
  loading = false,
}) => {
  const { t } = useCommonHooks();

  return (
    <Popover2
      position="right"
      content={
        <PopoverContent>
          <Menu>
            {menuItems.map((itemProps, i) => (
              <MenuItem {...itemProps} key={i} />
            ))}
          </Menu>
        </PopoverContent>
      }
    >
      <Button
        text={text ?? t(ETLCodes.GlobalActions)}
        intent={intent}
        minimal={minimal}
        loading={loading}
        rightIcon="caret-right"
      />
    </Popover2>
  );
};

export default GlobalActionsButton;
