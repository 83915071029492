import { Tag } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";
import { UnordoredListValue } from "./UnordoredListColumn";

const Container = styled.div`
  display: flex !important;
  gap: 0.5rem;
`;

export interface ITagListColumnProps {
  values: UnordoredListValue[];
}

export const TagListColumn: React.FunctionComponent<ITagListColumnProps> = ({
  values = [],
}) => {
  return (
    <Container>
      {values
        .filter((v) => v.value)
        .map((v) => (
          <Tag round intent="primary">
            {v.label}
          </Tag>
        ))}
    </Container>
  );
};
