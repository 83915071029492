import { Alignment, Classes, Colors, Navbar } from "@blueprintjs/core";
import { Classes as Classes2 } from "@blueprintjs/popover2";
import * as React from "react";
import styled from "styled-components";

import { AddButton, MenuButton } from ".";
import { ERoutes } from "../AppRouter";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";
import { SiteTheme } from "../theme";
import SearchField from "./SearchField";
import { UserMenu } from "./UserMenu";

interface IHeaderProps {
  toggleLeftMenu?: () => void;
  isDark?: boolean;
  onDarkSwitchChange?: (e: boolean) => void;
}

const NavBarStyled = styled(Navbar)<{ theme: SiteTheme }>`
  position: relative;
  height: 50px !important;
  background-color: transparent !important;
  color: ${(props) => props.theme.mainTextColor} !important;
  box-shadow: none !important;

  & .${Classes.BREADCRUMB} {
    color: ${(props) => props.theme.mainTextColor} !important;
  }

  & > .${Classes.NAVBAR_GROUP} {
    height: 50px;
  }

  & .${Classes2.POPOVER2}, .${Classes2.POPOVER2_CONTENT}, .${Classes.MENU} {
    border-radius: 16px !important;
  }
`;

const LogoImage = styled.img`
  height: 40px;
  margin-left: 5px;
`;
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 93%;

  &:hover {
    cursor: pointer;
  }
`;

export const Header: React.FC<IHeaderProps> = (props) => {
  const { theme, t, router } = useCommonHooks();

  return (
    <div
      className={Classes.DARK}
      style={{
        backgroundColor: theme.headerColor,
        borderBottom: `solid 1px ${Colors.GRAY1}`,
      }}
    >
      <NavBarStyled theme={theme}>
        <div style={{ position: "relative" }}>
          <Navbar.Group align={Alignment.LEFT}>
            <MenuButton onClick={props.toggleLeftMenu} />
            <Navbar.Divider />
            <LogoContainer
              style={{
                color: theme.highlightColor,
              }}
            >
              <LogoImage src={theme.logoWhiteUrl} alt="logo" />
              {/* <Navbar.Divider color="white" /> */}
              {/* <HeaderText>{theme.headerName}</HeaderText> */}
            </LogoContainer>
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT}>
            <SearchField />
            {/* <Popover2
              matchTargetWidth
              usePortal={false}
              minimal
              position="bottom"
              content={
                <Menu style={{ width: "100%", minWidth: 0 }}>
                  <MenuItem
                    text={t(ETLCodes.Suivi)}
                    onClick={() => router.push(ERoutes.newSuivis)}
                  />
                </Menu>
              }
            >
              <AddButton
                text={t(ETLCodes.New)}
                minimal={false}
                style={{ marginLeft: "1rem" }}
              />
            </Popover2> */}
            <AddButton
              text={t(ETLCodes.Suivi)}
              minimal={false}
              style={{ marginLeft: "1rem" }}
              onClick={() => router.push(ERoutes.newSuivis)}
            />
            <UserMenu />
          </Navbar.Group>
        </div>
      </NavBarStyled>
    </div>
  );
};
