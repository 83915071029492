import * as React from "react";
import { Route, Routes } from "react-router";

import { FullPageLoader } from "./components";

export enum ERoutes {
  translations = "/translations",
  dataTranslations = "/dataTranslations",
  notFound = "/notFound",
  unauthorized = "/unauthorized",
  home = "/",
  inbox = "/inbox",
  newSuivis = "/suivis/new",
  suivis = "/suivis",
  actions = "/actions",
  personnel = "/personnel",
  planLocalisation = "/planLocalisation",
  zipCodes = "/zipCodes",
  waitingList = "/waitingList",
  workRules = "/workRules",
  typeAnswers = "/typeAnswers",
  example = "/example",
  completedActions = "/completedActions",
  requestObjects = "/requestObjects",
  instituteManagements = "/instituteManagements",
  otherPartners = "/otherPartners",
  beneficiaryPersons = "/beneficiaryPersons",
  beneficiaryCompanies = "/beneficiaryCompanies",
  profile = "/profile",
  profileAvailability = "/profile/availability",
  suiviStatistics = "/suiviStatistics",
}

const getEditRoute = (base: ERoutes) => "" + base + "/:id/:tab";

const UserItemPage = React.lazy(
  () => import("./pages/users/item/userItemPage")
);
const UserListPage = React.lazy(
  () => import("./pages/users/list/userListPage")
);
const HomePage = React.lazy(() => import("./pages/home/HomePage"));

const TypeAnswerListPage = React.lazy(
  () => import("./pages/parameters/typeAnswers/list/TypeAnswerListPage")
);
const TypeAnswerItemPage = React.lazy(
  () => import("./pages/parameters/typeAnswers/item/TypeAnswerItemPage")
);

const ExamplePage = React.lazy(
  () => import("./pages/common/example/ExamplePage")
);

const CompletedActionItemPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/completedActions/item/CompletedActionItemPage"
    )
);
const CompletedActionListPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/completedActions/list/CompletedActionListPage"
    )
);

const RequestObjectItemPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/requestObjects/item/RequestObjectItemPage"
    )
);
const RequestObjectListPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/requestObjects/list/RequestObjectListPage"
    )
);

const InstituteManagementItemPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/recipientGroups/instituteManagement/item/InstituteManagementItemPage"
    )
);
const InstituteManagementListPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/recipientGroups/instituteManagement/list/InstituteManagementListPage"
    )
);

const OtherPartnerItemPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/recipientGroups/otherPartners/item/OtherPartnerItemPage"
    )
);
const OtherPartnerListPage = React.lazy(
  () =>
    import(
      "./pages/parameters/baseData/recipientGroups/otherPartners/list/OtherPartnerListPage"
    )
);

const WorkRuleItemPage = React.lazy(
  () => import("./pages/parameters/workRules/item/WorkRuleItemPage")
);
const WorkRuleListPage = React.lazy(
  () => import("./pages/parameters/workRules/list/WorkRuleListPage")
);

const BeneficiaryPersonListPage = React.lazy(
  () => import("./pages/beneficiaries/person/list/BeneficiaryPersonListPage")
);

const BeneficiaryPersonItemPage = React.lazy(
  () => import("./pages/beneficiaries/person/item/BeneficiaryPersonItemPage")
);

const BeneficiaryCompanyListPage = React.lazy(
  () =>
    import("./pages/beneficiaries/companies/list/BeneficiaryCompanyListPage")
);

const SuiviItemPage = React.lazy(
  () => import("./pages/suivis/item/SuiviItemPage")
);

const SuiviListPage = React.lazy(
  () => import("./pages/suivis/list/SuiviListPage")
);

const ActionListPage = React.lazy(
  () => import("./pages/actions/list/ActionListPage")
);

const InboxPage = React.lazy(() => import("./pages/inbox/InboxPage"));

const ProfilePage = React.lazy(() => import("./pages/profile/ProfilePage"));

const ProfileAvailabilityPage = React.lazy(
  () => import("./pages/profile/ProfileAvailabilityPage")
);

const SuiviStatistics = React.lazy(
  () => import("./pages/suivis/statistics/SuiviStatistics")
);

export const AppRouter = () => {
  return (
    <React.Suspense fallback={<FullPageLoader />}>
      <Routes>
        <Route path={ERoutes.home} element={<HomePage />} />
        <Route path={ERoutes.inbox + "/:id?"} element={<InboxPage />} />
        <Route path={ERoutes.typeAnswers} element={<TypeAnswerListPage />} />
        <Route
          path={getEditRoute(ERoutes.typeAnswers)}
          element={<TypeAnswerItemPage />}
        />
        <Route
          path={ERoutes.completedActions}
          element={<CompletedActionListPage />}
        />
        <Route
          path={getEditRoute(ERoutes.completedActions)}
          element={<CompletedActionItemPage />}
        />
        <Route
          path={ERoutes.requestObjects}
          element={<RequestObjectListPage />}
        />
        <Route
          path={getEditRoute(ERoutes.requestObjects)}
          element={<RequestObjectItemPage />}
        />
        <Route
          path={ERoutes.instituteManagements}
          element={<InstituteManagementListPage />}
        />
        <Route
          path={getEditRoute(ERoutes.instituteManagements)}
          element={<InstituteManagementItemPage />}
        />
        <Route
          path={ERoutes.otherPartners}
          element={<OtherPartnerListPage />}
        />
        <Route
          path={getEditRoute(ERoutes.otherPartners)}
          element={<OtherPartnerItemPage />}
        />
        <Route path={ERoutes.workRules} element={<WorkRuleListPage />} />
        <Route
          path={getEditRoute(ERoutes.workRules)}
          element={<WorkRuleItemPage />}
        />
        <Route
          path={ERoutes.beneficiaryPersons}
          element={<BeneficiaryPersonListPage />}
        />
        <Route
          path={getEditRoute(ERoutes.beneficiaryPersons)}
          element={<BeneficiaryPersonItemPage />}
        />
        <Route
          path={ERoutes.beneficiaryCompanies}
          element={<BeneficiaryCompanyListPage />}
        />
        <Route path={ERoutes.personnel} element={<UserListPage />} />
        <Route
          path={getEditRoute(ERoutes.personnel)}
          element={<UserItemPage />}
        />
        <Route path={ERoutes.newSuivis} element={<SuiviItemPage />} />
        <Route path={ERoutes.suivis} element={<SuiviListPage />} />
        <Route path={ERoutes.actions} element={<ActionListPage />} />
        <Route path={ERoutes.profile} element={<ProfilePage />} />
        <Route
          path={ERoutes.profileAvailability}
          element={<ProfileAvailabilityPage />}
        />
        <Route path={ERoutes.suiviStatistics} element={<SuiviStatistics />} />
        {/* TODO: Remove when unecessary */}
        <Route path={ERoutes.example} element={<ExamplePage />} />
      </Routes>
    </React.Suspense>
  );
};
