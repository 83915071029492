import placeholderPerson from "./placeholder-person.png";
import banner from "./banner.png";
import placeholderImage from "./image_placeholder.png";
import smileLogo from "./smile_logo_no_text.png";
import smileLogoWhite from "./smile_logo_white.png";
import { baseUrl } from "../apiConfig";

export function autoPrefixUrls(s: string) {
  if (!s.includes("data:image")) {
    return baseUrl + s;
  } else {
    return s;
  }
}

const assets = {
  images: {
    smileLogo: autoPrefixUrls(smileLogo),
    smileLogoWhite: autoPrefixUrls(smileLogoWhite),
    placeholderPerson: autoPrefixUrls(placeholderPerson),
    banner: autoPrefixUrls(banner),
    placeholderImage: autoPrefixUrls(placeholderImage),
  },
};

export default assets;
