import * as React from "react";

export interface IAppThemeProps {}

export const AppTheme: React.FunctionComponent<IAppThemeProps> = (props) => {
  React.useEffect(() => {
    const head = document.head;
    const link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "./BlueprintSmile.css";

    // Insert Blueprint Custom CSS BEFORE App CSS for it to work
    const appStyle = head.querySelector('[data-styled="active"]');
    head.insertBefore(link, appStyle);

    return () => {
      head.removeChild(link);
    };
  }, []);
  return null;
};
