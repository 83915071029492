import {
  Button,
  ButtonProps,
  Classes,
  Colors,
  Drawer,
  HotkeyConfig,
  MaybeElement,
  useHotkeys,
} from "@blueprintjs/core";
import { FormikProps } from "formik";
import {
  FGCustomPanel,
  FlexFieldGroup,
  FormGenerator,
  IFGContext,
  IFormGeneratorProps,
  InlineButtonContainer,
} from "nsitools-react";
import * as React from "react";
import styled from "styled-components";

import { CancelButton, DeleteButton, SaveButton } from ".";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";

export interface CustomFormGeneratorProps<T>
  extends Omit<IFormGeneratorProps<T>, "children"> {
  loading?: boolean;
  onCancel?: () => void;
  onDelete?: () => void;
  deleting?: boolean;
  saving?: boolean;
  editMode?: boolean;
  hideButtons?: boolean;
  editable?: boolean;
  cancelEdit?: boolean;
  deleteButtonPosition?: "left" | "right" | "none";
  bottomLeftButtonProps?: ((ctx: IFGContext) => ButtonProps[]) | ButtonProps[];
  bottomRightButtonProps?: ((ctx: IFGContext) => ButtonProps[]) | ButtonProps[];
  bottomLeftButtons?: (ctx: IFGContext) => MaybeElement;
  bottomRightButtons?: (ctx: IFGContext) => MaybeElement;
  dialogMode?: boolean;
  enableHotkeys?: boolean;
  maxWidth?: string | number;
  additionalButtons?: React.ReactNode;
  saveButtonClassName?: string;
  cancelButtonClassName?: string;
  drawerComponent?: React.ReactNode;
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
  drawerPushingPadding?: boolean;
  drawerUsePortal?: boolean;
  drawerPercentWidth?: number;
  saveButtonPosition?: "left" | "right" | "none";
  showLeftSaveButton?: boolean;
  baseRightPaddingPc?: number;
  baseLeftPaddingPc?: number;
  cancelButtonPosition?: "left" | "right";
}

const StyledForm = styled.div<{ leftPadding: string; rightPadding: string }>`
  & .${Classes.LABEL} {
    color: ${Colors.DARK_GRAY5};
    font-size: small;
  }

  & {
    input,
    textarea,
    .textContainer {
      font-weight: 500;
    }
  }

  & .${Classes.FORM_GROUP} {
    margin: 0 0 5px !important;
  }

  & .${Classes.HTML_TABLE} {
    border: 0 none !important;
  }

  flex: 1;
  padding-top: 0;
  padding-left: ${(props) => props.leftPadding};
  padding-right: ${(props) => props.rightPadding};
  padding-bottom: 0.5rem;

  & .${Classes.OVERLAY} {
    width: 42%;
    left: auto !important;

    @media (max-width: 1200px) {
      width: 58%;
    }
  }

  & .${Classes.DRAWER} {
    background: #f6f7f9 !important;
  }
`;

const CustomButtonContainer = styled(InlineButtonContainer)`
  .left,
  .right > * + * {
    margin-left: 0.25rem;
  }
`;

export function CustomFormGenerator<T>(
  props: React.PropsWithChildren<CustomFormGeneratorProps<T>>
) {
  const {
    children,
    loading = false,
    deleting,
    onDelete,
    onCancel,
    saving,
    minLabelWidth = 180,
    labelAlignment = "right",
    fill = true,
    showColons = false,
    inline = false,
    boldLabel = true,
    hideButtons = false,
    deleteButtonPosition = "right",
    bottomLeftButtonProps,
    bottomRightButtonProps,
    bottomLeftButtons,
    bottomRightButtons,
    editMode,
    dialogMode,
    enableHotkeys = false,
    maxWidth,
    additionalButtons,
    cancelButtonClassName,
    saveButtonClassName,
    drawerComponent,
    isDrawerOpen = false,
    onDrawerClose,
    drawerPushingPadding = true,
    saveButtonPosition = "left",
    showLeftSaveButton = false,
    baseRightPaddingPc = 15,
    baseLeftPaddingPc = 15,
    cancelButtonPosition = "left",
    drawerUsePortal = false,
    drawerPercentWidth = 100,
    ...otherProps
  } = props;
  const { t, tUnsafe, smallScreen } = useCommonHooks();
  const formikInnerRef = React.useRef<FormikProps<T>>();

  const isSaveAllowed = React.useCallback(
    (formik: FormikProps<T>) =>
      formik.dirty || formik.touched || formik.submitCount > 0,
    []
  );

  const hotkeys = React.useMemo<HotkeyConfig[]>(
    () =>
      enableHotkeys
        ? [
            {
              combo: "ctrl+s",
              global: true,
              label: t(ETLCodes.Save),
              preventDefault: true,
              stopPropagation: true,
              allowInInput: true,
              onKeyDown: () => {
                const formik =
                  (otherProps.formikInnerRef as any).current ??
                  formikInnerRef.current;
                return isSaveAllowed(formik) ? formik?.submitForm() : null;
              },
            },
          ]
        : [],
    [enableHotkeys, isSaveAllowed, otherProps.formikInnerRef, t]
  );

  useHotkeys(hotkeys);

  const getCustomLeftButtonProps = React.useCallback(
    (ctx: IFGContext<any>) => {
      let buttonProps: ButtonProps[];
      if (typeof bottomLeftButtonProps === "function") {
        buttonProps = bottomLeftButtonProps(ctx);
      } else {
        buttonProps = bottomLeftButtonProps;
      }
      return buttonProps?.map((btp) => <Button {...btp} />) || [];
    },
    [bottomLeftButtonProps]
  );

  const getCustomRightButtonProps = React.useCallback(
    (ctx: IFGContext<any>) => {
      let buttonProps: ButtonProps[];
      if (typeof bottomRightButtonProps === "function") {
        buttonProps = bottomRightButtonProps(ctx);
      } else {
        buttonProps = bottomRightButtonProps;
      }
      return buttonProps?.map((btp) => <Button {...btp} />) || [];
    },
    [bottomRightButtonProps]
  );

  const rightPaddingDelta = React.useMemo(
    () => (smallScreen ? 45 : 35),
    [smallScreen]
  );

  return (
    <StyledForm
      style={{
        maxWidth: maxWidth,
      }}
      leftPadding={
        isDrawerOpen && drawerPushingPadding ? "0" : `${baseLeftPaddingPc}%`
      }
      rightPadding={
        isDrawerOpen && drawerPushingPadding
          ? `${baseRightPaddingPc + rightPaddingDelta}%`
          : `${baseRightPaddingPc}%`
      }
      className="form-container"
    >
      <FormGenerator
        minLabelWidth={minLabelWidth}
        labelAlignment={inline ? labelAlignment : "left"}
        fill={fill}
        inline={inline}
        boldLabel={boldLabel}
        showColons={showColons}
        loading={loading}
        translateFn={tUnsafe}
        formikInnerRef={formikInnerRef}
        responsiveColumnMinWidth={600}
        editMode={editMode}
        {...otherProps}
        touchOnSubmit
      >
        <FlexFieldGroup gap="0.5rem">
          {children}
          {!hideButtons && (
            <FGCustomPanel>
              {(ctx) => (
                <CustomButtonContainer>
                  <div
                    className="left"
                    style={{ display: "flex", gap: "0.5rem" }}
                  >
                    {onDelete &&
                      editMode &&
                      deleteButtonPosition === "left" && (
                        <DeleteButton
                          minimal={false}
                          loading={deleting}
                          onDelete={onDelete}
                        />
                      )}
                    {editMode && saveButtonPosition === "left" && (
                      <SaveButton
                        className={saveButtonClassName}
                        minimal={false}
                        loading={saving}
                        disabled={!isSaveAllowed(ctx?.formik)}
                      />
                    )}
                    {onCancel && cancelButtonPosition === "left" && (
                      <CancelButton
                        minimal={false}
                        onClick={onCancel}
                        className={cancelButtonClassName}
                      />
                    )}
                    {bottomLeftButtons && bottomLeftButtons(ctx)}
                    {getCustomLeftButtonProps(ctx)}
                    {editMode && showLeftSaveButton && (
                      <SaveButton
                        className={saveButtonClassName}
                        minimal={false}
                        loading={saving}
                        disabled={!isSaveAllowed(ctx?.formik)}
                      />
                    )}
                  </div>
                  <div className="right">
                    {bottomRightButtons && bottomRightButtons(ctx)}
                    {getCustomRightButtonProps(ctx)}
                    {additionalButtons}
                    {onDelete &&
                      editMode &&
                      deleteButtonPosition === "right" && (
                        <DeleteButton
                          minimal={false}
                          loading={deleting}
                          onDelete={onDelete}
                        />
                      )}
                    {editMode && saveButtonPosition === "right" && (
                      <SaveButton
                        className={saveButtonClassName}
                        minimal={false}
                        loading={saving}
                        disabled={!isSaveAllowed(ctx?.formik)}
                      />
                    )}
                    {onCancel && cancelButtonPosition === "right" && (
                      <CancelButton
                        minimal={false}
                        onClick={onCancel}
                        className={cancelButtonClassName}
                      />
                    )}
                  </div>
                </CustomButtonContainer>
              )}
            </FGCustomPanel>
          )}
        </FlexFieldGroup>
      </FormGenerator>
      {drawerComponent && (
        <Drawer
          hasBackdrop={false}
          usePortal={drawerUsePortal}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          canOutsideClickClose={false}
          canEscapeKeyClose={false}
          enforceFocus={false}
          size={drawerPercentWidth + "%"}
        >
          {drawerComponent}
        </Drawer>
      )}
    </StyledForm>
  );
}
