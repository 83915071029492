import { Colors, Icon, IconName, MaybeElement } from "@blueprintjs/core";
import * as React from "react";

export interface IDataTableBooleanColumnProps {
  checked: boolean;
  hideFalseValue?: boolean;
  trueIcon?: IconName | MaybeElement;
  falseIcon?: IconName | MaybeElement;
  highlighted?: boolean;
}

export const DataTableBooleanColumn: React.FunctionComponent<
  IDataTableBooleanColumnProps
> = ({ checked, hideFalseValue, trueIcon, falseIcon, highlighted }) => {
  return checked ? (
    <Icon
      icon={trueIcon ?? "tick"}
      color={highlighted ? Colors.FOREST5 : Colors.GREEN3}
    />
  ) : hideFalseValue ? (
    <></>
  ) : (
    <Icon
      icon={falseIcon ?? "cross"}
      color={highlighted ? Colors.RED4 : Colors.RED3}
    />
  );
};
