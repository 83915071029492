import { ActionCode } from "./api";
import { ERoutes } from "./AppRouter";
import { INavigationItem } from "./components";
import { ETLCodes } from "./locales";

export enum NAV_ITEM_TO_REPLACE {
  FAVOURITES = "FAVOURITES",
}

export const BaseAppNavigation: INavigationItem[] = [
  {
    name: ETLCodes.Home,
    icon: "home",
    route: ERoutes.home,
  },
  {
    name: ETLCodes.Inbox,
    icon: "inbox",
    route: ERoutes.inbox,
  },
  {
    name: ETLCodes.MesSuivis,
    icon: "edit",
    route: ERoutes.suivis,
  },
  {
    name: ETLCodes.MesActions,
    icon: "saved",
    route: ERoutes.actions,
  },
  {
    name: ETLCodes.MonIfapme,
    icon: "diagram-tree",
    items: [
      {
        name: ETLCodes.Beneficiaires,
        items: [
          {
            name: ETLCodes.Personnes,
            route: ERoutes.beneficiaryPersons,
          },
          {
            name: ETLCodes.Entreprises,
            route: ERoutes.beneficiaryCompanies,
          },
        ],
      },
      {
        name: ETLCodes.MembresDuPersonnel,
        route: ERoutes.personnel,
        actions: [ActionCode.ViewUser],
      },
      {
        name: ETLCodes.PlanLocalisation,
        route: ERoutes.planLocalisation,
      },
      {
        name: ETLCodes.TableauCodesPostaux,
        route: ERoutes.zipCodes,
      },
      {
        name: ETLCodes.ClassesSoldOutListeAttente,
        route: ERoutes.waitingList,
      },
    ],
  },
  {
    name: ETLCodes.MesFavoris,
    icon: "bookmark",
    toReplaceName: NAV_ITEM_TO_REPLACE.FAVOURITES,
  },
  {
    name: ETLCodes.Parametres,
    icon: "cog",
    items: [
      {
        name: ETLCodes.WorkRules,
        route: ERoutes.workRules,
      },
      {
        name: ETLCodes.TypeAnwsers,
        route: ERoutes.typeAnswers,
      },
      {
        name: ETLCodes.BaseData,
        items: [
          {
            name: ETLCodes.CompletedActions,
            route: ERoutes.completedActions,
          },
          {
            name: ETLCodes.RequestObjects,
            route: ERoutes.requestObjects,
          },
          {
            name: ETLCodes.RecipientGroups,
            items: [
              {
                name: ETLCodes.InstituteManagement,
                route: ERoutes.instituteManagements,
              },
              {
                name: ETLCodes.OtherPartners,
                route: ERoutes.otherPartners,
              },
            ],
          },
        ],
      },
    ],
  },
];

const InnerFindAppNavigationItemFromRoute = (
  pathname: string,
  navItem: INavigationItem
): INavigationItem | null => {
  if (navItem && navItem.route && pathname.startsWith(navItem.route)) {
    return navItem;
  }
  const nextNavItems = navItem.items;
  if (nextNavItems && nextNavItems?.length > 0) {
    for (let i of nextNavItems) {
      const found = InnerFindAppNavigationItemFromRoute(pathname, i);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const FindAppNavigationItemFromRoute = (pathname: string) => {
  for (let i of BaseAppNavigation) {
    const found = InnerFindAppNavigationItemFromRoute(pathname, i);
    if (found) return found;
  }
  return null;
};
