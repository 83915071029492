import { BreadcrumbProps } from "@blueprintjs/core";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";

const baseBreadCrumbAtom = atom<BreadcrumbProps[]>({
  key: "baseBreadCrumb",
  default: [],
});

const detailsBreadCrumbAtom = atom<BreadcrumbProps[]>({
  key: "detailsBreadCrumb",
  default: [],
});

const breadCrumbSelector = selector({
  key: "completeBreadCrumbSelector",
  get: ({ get }) => {
    const baseBC = get(baseBreadCrumbAtom);
    const detailBC = get(detailsBreadCrumbAtom);

    return [...baseBC, ...detailBC];
  },
});

export const useBreadcrumbData = () => {
  const setBaseBreadCrumb = useSetRecoilState(baseBreadCrumbAtom);
  const setDetailBreadCrumb = useSetRecoilState(detailsBreadCrumbAtom);

  const breadCrumbs = useRecoilValue(breadCrumbSelector);

  return {
    setBaseBreadCrumb,
    setDetailBreadCrumb,
    breadCrumbs,
  };
};
