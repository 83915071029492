import { Boundary, Breadcrumbs, BreadcrumbsProps } from "@blueprintjs/core";
import * as React from "react";

interface IAppBreadcrumbProps extends Pick<BreadcrumbsProps, "items"> {}

export const AppBreadcrumb: React.FunctionComponent<IAppBreadcrumbProps> = ({
  items,
}) => {
  React.useEffect(() => {
    if (items?.length > 0) {
      document.title =
        items
          .filter((i) => !!i.text && typeof i.text === "string")
          .map((i) => i.text)
          .join(" > ") + " - Smile";
    } else {
      document.title = "Smile";
    }
  }, [items]);
  return <Breadcrumbs items={items} collapseFrom={Boundary.START} />;
};
