import {
  Alignment,
  Button,
  Classes,
  Navbar,
  Panel,
  PanelStack2,
} from "@blueprintjs/core";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import * as Yup from "yup";

import {
  EditBeneficiaryPersonDto,
  EmailDto,
  Forme,
  SearchBeneficiaryDto,
  TelephoneDto,
} from "../../../api";
import { useCommonHooks } from "../../../hooks";
import { useCrudApi } from "../../../hooks/useCrudApi";
import { ETLCodes } from "../../../locales";
import { SiteTheme } from "../../../theme";
import { CustomFormGenerator } from "../../CustomFormGenerator";
import BeneficiarySelectorCiteriasPanel, {
  IBeneficiarySelectorCiteriasPanelProps,
} from "./BeneficiarySelectorCiteriasPanel";
import { IBeneficiarySelectorResultsPanelProps } from "./BeneficiarySelectorResultsPanel";

const Container = styled.div`
  height: 100%;
  & .beneficiary-selector-panel-stack {
    height: 100%;
    min-height: 90vh;
    width: 100%;
    & .${Classes.PANEL_STACK2_VIEW} {
      padding: 1px;
      background-color: transparent !important;
    }
  }
`;

const NavBarStyled = styled(Navbar)<{ theme: SiteTheme }>`
  position: relative;
  height: 50px !important;
  color: ${(props) => props.theme.mainTextColor} !important;
  box-shadow: none !important;
  margin-bottom: 1rem;

  & .${Classes.NAVBAR_HEADING} {
    color: ${(props) => props.theme.mainTextColor} !important;
    font-weight: 600;
  }

  & > .${Classes.NAVBAR_GROUP} {
    height: 50px;
  }
`;

export interface IBeneficiarySelectorProps {
  onSelected: (value: number, forme: Forme, idpersonne: number) => void;
  onClose: () => void;
  telephone?: string;
}

export const BeneficiarySelector: React.FunctionComponent<
  IBeneficiarySelectorProps
> = ({ onSelected, onClose, telephone }) => {
  const { t, theme, apis } = useCommonHooks();

  const isEntreprise = React.useCallback(
    (forme: Forme) => [Forme.ChefEntreprise, Forme.Contact].includes(forme),
    []
  );

  const { data: typesTel } = useQuery(["types-tel-raw"], async () =>
    apis.referentials.phoneTypes()
  );
  const idtypeTelPerso = React.useMemo(
    () => typesTel?.find((tt) => tt.keyValue === "PERSO")?.idValue,
    [typesTel]
  );
  const idtypeTelGsmPerso = React.useMemo(
    () => typesTel?.find((tt) => tt.keyValue === "GSM_PERSO")?.idValue,
    [typesTel]
  );

  const { data: typesMail } = useQuery(["types-mail-raw"], async () =>
    apis.referentials.emailTypes()
  );
  const idtypeEmailPerso = React.useMemo(
    () => typesMail?.find((tt) => tt.keyValue === "PERSO")?.idValue,
    [typesMail]
  );

  const { data, validationErrors, onSave, isSaving } = useCrudApi({
    queryKey: ["beneficiary-suivi"],
    fetchFn: async () => {
      return SearchBeneficiaryDto.fromJS({ telephone });
    },
    saveFn: async (toSave: SearchBeneficiaryDto) => {
      let personPhones = [];
      if (toSave.telephone)
        personPhones.push(
          TelephoneDto.fromJS({
            numero: toSave.telephone,
            idtypeTelephone: +idtypeTelPerso,
          })
        );

      if (toSave.gsm)
        personPhones.push(
          TelephoneDto.fromJS({
            numero: toSave.gsm,
            idtypeTelephone: +idtypeTelGsmPerso,
          })
        );

      let personEmails = [];
      if (toSave.email)
        personEmails.push(
          EmailDto.fromJS({
            adresseEmail: toSave.email,
            idtypeEmail: +idtypeEmailPerso,
          } as EmailDto)
        );

      const saved = await apis.beneficiaryPersons.saveBeneficiaryPerson(
        EditBeneficiaryPersonDto.fromJS({
          ...toSave,
          telephones: personPhones,
          emails: personEmails,
          adresseDomicile: toSave.adresse,
          formeFromSuivi: toSave.forme,
        })
      );
      return SearchBeneficiaryDto.fromJS({ ...toSave, ...saved });
    },
    onSaved: (saved: SearchBeneficiaryDto) => {
      let id;
      switch (saved.forme) {
        case Forme.Apprenant:
          id = saved.idapprenant;
          break;
        case Forme.Representant:
          id = saved.idrepresentant;
          break;
        case Forme.Formateur:
          id = saved.idformateur;
          break;
        case Forme.ChefEntreprise:
          id = saved.idchefEntreprise;
          break;
        case Forme.Tuteur:
          id = saved.idtuteur;
          break;
        case Forme.Contact:
          id = saved.idcontact;
          break;
      }

      onSelected(id, saved.forme, saved.idpersonne);
    },
    serverValidationRootKey: "EditSuiviDto",
  });

  const initialPanel: Panel<
    | IBeneficiarySelectorCiteriasPanelProps
    | IBeneficiarySelectorResultsPanelProps
  > = {
    renderPanel: BeneficiarySelectorCiteriasPanel,
    props: {
      isEntreprise,
      onSelect: onSelected,
      onClose,
      saving: isSaving,
      telephone,
    },
  };

  const [currentPanelStack, setCurrentPanelStack] = React.useState<
    Array<
      Panel<
        | IBeneficiarySelectorCiteriasPanelProps
        | IBeneficiarySelectorResultsPanelProps
      >
    >
  >([initialPanel]);

  const addToPanelStack = React.useCallback(
    (
      newPanel: Panel<
        | IBeneficiarySelectorCiteriasPanelProps
        | IBeneficiarySelectorResultsPanelProps
      >
    ) => setCurrentPanelStack((stack) => [...stack, newPanel]),
    []
  );
  const removeFromPanelStack = React.useCallback(
    () => setCurrentPanelStack(() => currentPanelStack.slice(0, -1)),
    [currentPanelStack]
  );

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        forme: Yup.string().when("isBeneficiaryCreation", {
          is: true,
          then: Yup.string().required(t(ETLCodes.Required)),
        }),
        nom: Yup.string().when("forme", {
          is: (val) => !isEntreprise(val),
          then: Yup.string().required(t(ETLCodes.Required)),
        }),
        prenom: Yup.string().when("forme", {
          is: (val) => !isEntreprise(val),
          then: Yup.string().required(t(ETLCodes.Required)),
        }),
      }),
    [isEntreprise, t]
  );

  return (
    <Container>
      <NavBarStyled theme={theme}>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>{t(ETLCodes.SelectionBeneficiaire)}</Navbar.Heading>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Button
            title={t(ETLCodes.Close)}
            icon="arrow-right"
            minimal={true}
            onClick={onClose}
          />
        </Navbar.Group>
      </NavBarStyled>
      <CustomFormGenerator
        initialValues={data}
        editable={true}
        editMode={true}
        saving={isSaving}
        validationSchema={validationSchema}
        validationErrors={validationErrors}
        onSubmit={onSave}
        preventDefaultSaveOnEnter
        deleteButtonPosition="none"
        saveButtonPosition="none"
        baseLeftPaddingPc={5}
        baseRightPaddingPc={5}
      >
        <PanelStack2
          className="beneficiary-selector-panel-stack"
          onOpen={addToPanelStack}
          onClose={removeFromPanelStack}
          renderActivePanelOnly={false}
          showPanelHeader={false}
          stack={currentPanelStack}
        />
      </CustomFormGenerator>
    </Container>
  );
};

export default BeneficiarySelector;
