import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IAddButtonProps extends ICustomButtonProps {
  fill?: boolean;
}

export const AddButton: React.FunctionComponent<IAddButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.PRIMARY,
  text,
  hideText,
  small,
  style,
  fill,
  outlined,
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.PLUS}
      minimal={minimal}
      onClick={onClick}
      text={!hideText ? text || t(ETLCodes.Add) : null}
      intent={intent}
      loading={loading}
      disabled={disabled}
      small={small}
      style={style}
      fill={fill}
      outlined={outlined}
    />
  );
};
