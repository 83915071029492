import { Colors } from "@blueprintjs/core";
import * as React from "react";
import styled from "styled-components";

const StyledSpan = styled.span<{ color: string }>`
  display: flex;
  color: ${(props) => props.color};
`;

export interface IWarningTextProps {
  text: string;
}

export const WarningText: React.FunctionComponent<IWarningTextProps> = ({
  text,
}) => {
  return <StyledSpan color={Colors.ORANGE3}>{text}</StyledSpan>;
};
