import { Colors, Drawer, DrawerProps } from "@blueprintjs/core";
import classNames from "classnames";
import * as React from "react";
import styled from "styled-components";
import { useHopeViewVerticalTabsContext } from "../../contexts";

const VerticalTabs = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -1px;
  right: 42%;
  z-index: 99;
  background: ${Colors.WHITE};
  border-radius: 0 0 0 5px;
  border: solid 1px ${Colors.GRAY5};
`;

const VerticalTab = styled.div`
  width: 30px;
  writing-mode: vertical-lr;
  text-orientation: mixed;
  transform: rotateZ(180deg);
  background: ${Colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-weight: 600;
  border-radius: 0 5px 0 0;

  &.interactive:hover {
    cursor: pointer;
    background: ${Colors.LIGHT_GRAY5};
  }
  &.interactive {
    border-top: solid 1px ${Colors.GRAY5};
    background: ${Colors.LIGHT_GRAY3};
  }
`;

export interface IAdvancedSearchTableDrawerWrapperProps extends DrawerProps {}

export const AdvancedSearchTableDrawerWrapper: React.FunctionComponent<
  IAdvancedSearchTableDrawerWrapperProps
> = (props) => {
  const { tabs, removeTabsAfter } = useHopeViewVerticalTabsContext();

  return (
    <>
      {tabs.length > 1 && (
        <VerticalTabs>
          {tabs.map((tab, i) => (
            <VerticalTab
              className={classNames(
                { interactive: i < tabs.length - 1 },
                "v-tab"
              )}
              onClick={
                i < tabs.length - 1 &&
                (() => {
                  removeTabsAfter(tab);
                  tab.onClick();
                })
              }
              key={i}
            >
              {tab.label}
            </VerticalTab>
          ))}
        </VerticalTabs>
      )}
      <Drawer {...props}>{props.children}</Drawer>
    </>
  );
};

export default AdvancedSearchTableDrawerWrapper;
