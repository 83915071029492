import { Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { ToolTipButton } from "nsitools-react";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ICopyButtonProps extends ICustomButtonProps {}

export const CopyButton: React.FunctionComponent<ICopyButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
}) => {
  const { t } = useTl();

  return (
    <ToolTipButton
      icon={IconNames.DUPLICATE}
      tooltip={t(ETLCodes.Copy)}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Copy)}
      intent={intent}
      loading={loading}
      disabled={disabled}
    />
  );
};
