import {
  Button,
  Classes,
  Colors,
  MenuDivider,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { Editor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import { getIn } from "formik";
import { FGCustomInput, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";

const Container = styled.div`
  display: flex;
  flex: 1;

  & .editor-container {
    flex: 3;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MiddleIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: solid 1px ${Colors.LIGHT_GRAY3};
  padding: 0 0.5rem;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  & *:focus {
    outline: none !important;
  }s
`;

const MenuStyled = styled.div`
  background: transparent !important;
  flex: 1;
  & li {
    list-style-type: none;
  }
`;

export interface IFGMailEditorProps {
  name: string;
  label?: string;
  disabled?: boolean;
  height?: number;
  customButtons?: { name: string; opts: any }[];
  loading?: boolean;
}

export const FGMailEditor: React.FunctionComponent<IFGMailEditorProps> = ({
  name,
  label,
  disabled,
  height = 600,
  customButtons = [],
  loading = false,
}) => {
  const { apis, t } = useCommonHooks();

  const { data: variables, isFetching: loadingVariables } = useQuery(
    "referential-variables",
    React.useCallback(
      async () => await apis.referentials.variables(),
      [apis.referentials]
    )
  );

  const { formik, loading: globalLoading, editMode } = useFGContext();

  const onEditorChange = React.useCallback(
    (val: string, editor: any) => {
      formik.setFieldValue(name, val);
    },
    [formik, name]
  );

  const editorInstance = React.useRef<any>();

  const insertText = React.useCallback((text: string) => {
    editorInstance.current.insertContent(text);
  }, []);

  const onEditorReady = React.useCallback(
    (instance: any) => {
      for (const customButton of customButtons) {
        instance.ui.registry.addButton(customButton.name, customButton.opts);
      }
      editorInstance.current = instance;
    },
    [customButtons]
  );

  const [showVariablesPane, setShowVariablesPane] = React.useState(editMode);
  const onAddFieldDialogClosed = React.useCallback(
    (value: string) => {
      const formattedValue = `{{${value}}}`;
      insertText(formattedValue);
    },
    [insertText]
  );

  const [innerEditMode, setInnerEditMode] = React.useState(true);
  const initInsert = React.useCallback(
    (editor: any) => {
      const value = getIn(formik.values, name);
      if (value) editor.insertContent(value);
      setInnerEditMode(editMode);
    },
    [editMode, formik.values, name]
  );

  return (
    <Container>
      <FGCustomInput
        name={name}
        noLabel={!label}
        label={label}
        className={classNames("editor-container", {
          [Classes.SKELETON]: globalLoading || loading,
        })}
      >
        {(ctx) =>
          !globalLoading &&
          !loading && (
            <Editor
              apiKey="fhn90cu3e856omevc1xlq3rnxtxpc96kcsb6rmopovh0h0a2"
              onEditorChange={onEditorChange}
              disabled={disabled || !innerEditMode}
              init={{
                height: height,
                width: "100%",
                branding: false,
                menubar: false,
                elementpath: false,
                help_accessibility: false,
                language: "fr_FR",
                setup: (editor) => onEditorReady(editor),
                init_instance_callback: (e) => initInsert(e),
                plugins: [
                  "advlist",
                  "autolink",
                  "lists",
                  "link",
                  "image",
                  "charmap",
                  "preview",
                  "anchor",
                  "emoticons",
                  "searchreplace",
                  "visualblocks",
                  "code",
                  "fullscreen",
                  "insertdatetime",
                  "media",
                  "table",
                  "code",
                ],
                toolbar:
                  customButtons.map((b) => b.name).join(" ") +
                  " " +
                  "undo redo | blocks | " +
                  "bold italic forecolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat",
              }}
            />
          )
        }
      </FGCustomInput>
      {editMode && (
        <MenuContainer
          style={{
            flex: showVariablesPane ? 1 : null,
          }}
        >
          <MiddleIconContainer>
            <Button
              icon={showVariablesPane ? "chevron-right" : "chevron-left"}
              minimal
              onClick={() => setShowVariablesPane((prev) => !prev)}
              style={{ height: "100%" }}
            />
          </MiddleIconContainer>
          <MenuStyled
            style={{
              maxWidth: showVariablesPane ? "100%" : 0,
              visibility: showVariablesPane ? "visible" : "hidden",
            }}
          >
            <MenuDivider title={t(ETLCodes.Variables)} />
            {loadingVariables ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              variables?.map((v) => (
                <MenuItem
                  text={v.displayValue}
                  onClick={(e) => onAddFieldDialogClosed(v.idValue)}
                />
              ))
            )}
          </MenuStyled>
        </MenuContainer>
      )}
    </Container>
  );
};

export default FGMailEditor;
