import { Button, Intent } from "@blueprintjs/core";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface INextButtonProps extends ICustomButtonProps {}

export const NextButton: React.FunctionComponent<INextButtonProps> = ({
  minimal,
  onClick,
  disabled,
  loading,
  intent = Intent.PRIMARY,
  style,
  className,
}) => {
  const { t } = useTl();

  return (
    <Button
      rightIcon="chevron-right"
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Suivant)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      style={style}
      className={className}
    />
  );
};
