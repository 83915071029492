import * as React from "react";
import { ActionCode } from "../api";
import { useAuth } from "../contexts";

export interface ICanDoActionProps {
  actions: ActionCode[];
  children: React.ReactNode;
}

export const CanDoAction: React.FunctionComponent<ICanDoActionProps> = ({
  actions,
  children,
}) => {
  const { hasAction } = useAuth();

  return hasAction(actions) && <>{children}</>;
};
