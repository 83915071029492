import { getIn } from "formik";
import { FGSelectInput, useFGContext } from "nsitools-react";
import React from "react";
import { useQuery } from "react-query";
import { ReferentialItemDto } from "../../api";
import { useCommonHooks } from "../../hooks";
import { ETLCodes } from "../../locales";
import { nameof } from "../../utils";

interface INationaliteSelectProps {
  name: string;
  readonly?: boolean;
  codeSexeFieldName?: string;
  visible?: boolean;
}

export const NationaliteSelect: React.FunctionComponent<
  INationaliteSelectProps
> = ({ name, readonly, codeSexeFieldName, visible }) => {
  const { t, apis } = useCommonHooks();
  const ctx = useFGContext();
  const { formik } = ctx;
  const currentCodeSexe = React.useMemo(() => {
    if (!codeSexeFieldName) return null;
    return getIn(formik.values, codeSexeFieldName);
  }, [codeSexeFieldName, formik.values]);

  const { data, isFetching } = useQuery(
    ["ref-nationalities", currentCodeSexe],
    async () => {
      return await apis.referentials.nationalities(currentCodeSexe);
    }
  );

  return (
    <FGSelectInput
      name={name}
      label={t(ETLCodes.Nationalite)}
      loading={isFetching}
      items={data}
      readonly={readonly}
      displayField={nameof<ReferentialItemDto>("displayValue")}
      valueField={nameof<ReferentialItemDto>("idValue")}
      visible={visible}
    />
  );
};
