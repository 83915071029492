import { useLocation } from "react-router";
import { useApiService, useRouter, useSmallScreen, useTl } from "..";
import {
  ActionApi,
  BeneficiaryCompanyApi,
  BeneficiaryPersonApi,
  CompletedActionApi,
  GlobalSearchApi,
  HopeViewApi,
  InboxApi,
  InstituteManagementApi,
  NavFavouriteApi,
  OtherPartnerApi,
  ReferentialApi,
  ReponseTypeApi,
  RequestObjectApi,
  SuiviApi,
  SuiviStatisticsApi,
  UserApi,
  WorkRuleApi,
} from "../../api";
import {
  useAuth,
  useBreadcrumbData,
  useDialog,
  useEventsContext,
  useGlobalData,
  useNavigationContext,
} from "../../contexts";
import { getTheme } from "../../theme";
import { useManageValidationErrors } from "../useManageValidationErrors";
import { useValidators } from "./useValidators";

export function useCommonHooks() {
  return {
    ...useTl(),
    router: useRouter(),
    ...useBreadcrumbData(),
    ...useAuth(),
    ...useGlobalData(),
    ...useEventsContext(),
    ...useNavigationContext(),
    ...useDialog(),
    ...useManageValidationErrors(),
    ...useSmallScreen(),
    ...useLocation(),
    validators: useValidators(),
    theme: getTheme("base"),
    apis: {
      user: useApiService(UserApi),
      referentials: useApiService(ReferentialApi),
      reponsesTypes: useApiService(ReponseTypeApi),
      navFavourites: useApiService(NavFavouriteApi),
      hopeView: useApiService(HopeViewApi),
      completedActions: useApiService(CompletedActionApi),
      requestObjects: useApiService(RequestObjectApi),
      instituteManagements: useApiService(InstituteManagementApi),
      otherPartners: useApiService(OtherPartnerApi),
      workRules: useApiService(WorkRuleApi),
      beneficiaryPersons: useApiService(BeneficiaryPersonApi),
      beneficiaryCompanies: useApiService(BeneficiaryCompanyApi),
      suivis: useApiService(SuiviApi),
      actions: useApiService(ActionApi),
      inbox: useApiService(InboxApi),
      globalSearch: useApiService(GlobalSearchApi),
      suiviStatistics: useApiService(SuiviStatisticsApi),
    },
  };
}
