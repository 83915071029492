import { Classes } from "@blueprintjs/core";
import Cleave from "cleave.js/react";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div``;

export interface IColumnMaskProps {
  value: any;
  cleaveOptions: any;
}

export const ColumnMask: React.FunctionComponent<IColumnMaskProps> = ({
  value,
  cleaveOptions,
}) => {
  return (
    !!value && (
      <Container>
        <Cleave
          className={Classes.INPUT}
          value={value}
          type="text"
          options={cleaveOptions}
          style={{ boxShadow: "none", backgroundColor: "transparent" }}
          readOnly
        ></Cleave>
      </Container>
    )
  );
};
