import { Button, ControlGroup, HTMLSelect } from "@blueprintjs/core";
import { FieldArray, getIn } from "formik";
import {
  FGCustomInput,
  FGTextInput,
  IFGTextInputProps,
  useFGContext,
} from "nsitools-react";
import React from "react";
import styled from "styled-components";

const CustomHtmlSelect = styled(HTMLSelect)<{ width: number }>`
  width: ${(props) => props.width}px !important;
  flex: none !important;
  box-shadow: none;
  background-color: transparent !important;

  & > select {
    background-color: transparent;
  }
`;

const CustomButton = styled(Button)`
  flex: none !important;
`;
const AddButton = styled(Button)`
  align-self: flex-start;
`;
const FieldArrayContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.25rem;
  }
`;

export interface FGListTextInputProps
  extends Omit<IFGTextInputProps, "children" | "name" | "label"> {
  name: string;
  label?: string;
  selectValueField?: string;
  selectDisplayField?: string;
  resultIdField: string;
  resultTextField: string;
  items: Array<object>;
  loading: boolean;
  selectWidth?: number;
  dtoType: new (any) => any;
  allowMultipleItemsOfSameType?: boolean;
  maxItems?: number;
}

export const FGListTextInput: React.FC<FGListTextInputProps> = ({
  label,
  name,
  selectDisplayField = "displayValue",
  selectValueField = "idValue",
  resultIdField,
  resultTextField,
  items = [],
  loading,
  selectWidth = 120,
  dtoType,
  allowMultipleItemsOfSameType = false,
  maxItems,
  ...textInputProps
}) => {
  const { fill, formik } = useFGContext();

  const lastHtmlSelectRef = React.useRef<HTMLSelectElement>();

  const data = React.useMemo(() => {
    return getIn(formik.values, name) || [];
  }, [formik.values, name]);

  React.useEffect(() => {
    if (items && data) {
      lastHtmlSelectRef.current?.dispatchEvent(
        new Event("change", {
          bubbles: true,
          cancelable: true,
          composed: true,
        })
      );
    }
  }, [data, items]);

  const notSelectedItems = React.useMemo(() => {
    return items.filter(
      (it) => !data.map((d) => d[resultIdField]).includes(it[selectValueField])
    );
  }, [data, items, resultIdField, selectValueField]);

  const getAvailableItems = React.useCallback(
    (current: object) => {
      if (allowMultipleItemsOfSameType) return items;
      const itemForCurrent = items.find(
        (d) => d[selectValueField] === current[resultIdField]
      );
      return notSelectedItems.concat(itemForCurrent).filter((d) => !!d);
    },
    [
      allowMultipleItemsOfSameType,
      items,
      notSelectedItems,
      resultIdField,
      selectValueField,
    ]
  );

  const canAdd = React.useMemo(() => {
    return (
      (allowMultipleItemsOfSameType || notSelectedItems.length) > 0 &&
      ((maxItems && data.length < maxItems) || !maxItems)
    );
  }, [
    allowMultipleItemsOfSameType,
    data.length,
    maxItems,
    notSelectedItems.length,
  ]);

  return (
    <FGCustomInput name={name} label={label}>
      {({ formik }) => (
        <FieldArray
          name={name}
          render={(arrayHelper) => {
            return (
              <FieldArrayContainer>
                {data?.map((cf, index) => {
                  const selectValueName = `${name}.${index}.${resultIdField}`;
                  const inputValueName = `${name}.${index}.${resultTextField}`;
                  const itemsAvailable = getAvailableItems(cf);
                  return (
                    <ControlGroup fill={fill} vertical={false}>
                      {loading ? (
                        <CustomButton loading={true} />
                      ) : (
                        <CustomHtmlSelect
                          elementRef={lastHtmlSelectRef}
                          width={selectWidth}
                          name={selectValueName}
                          onChange={formik.handleChange}
                          value={getIn(formik.values, selectValueName)}
                        >
                          {itemsAvailable?.map((item, i) => {
                            return (
                              <option value={item[selectValueField]} key={i}>
                                {item[selectDisplayField]}
                              </option>
                            );
                          })}
                        </CustomHtmlSelect>
                      )}
                      <FGTextInput
                        name={inputValueName}
                        rightElement={
                          data.length > 1 ? (
                            <Button
                              minimal
                              icon="trash"
                              intent={"danger"}
                              onClick={() => arrayHelper.remove(index)}
                              disabled={data.length <= 1}
                            />
                          ) : null
                        }
                        {...textInputProps}
                        noLabel
                        noMargin
                      />
                    </ControlGroup>
                  );
                })}
                {canAdd ? (
                  <AddButton
                    small={true}
                    minimal={true}
                    intent={"primary"}
                    icon={"small-plus"}
                    onClick={() => arrayHelper.push(new dtoType({}))}
                  />
                ) : null}
              </FieldArrayContainer>
            );
          }}
        />
      )}
    </FGCustomInput>
  );
};
