import * as React from "react";

export interface HopeViewVerticalTab {
  label: string;
  onClick: () => void;
}

interface IHopeViewVerticalTabsContextContext {
  tabs: HopeViewVerticalTab[];
  pushTab: (tab: HopeViewVerticalTab) => void;
  removeTabsAfter: (clickedTab: HopeViewVerticalTab) => void;
  clear: () => void;
  removeLastTab: () => void;
}
const HopeViewVerticalTabsContextContext =
  React.createContext<IHopeViewVerticalTabsContextContext>(null);

interface IHopeViewVerticalTabsContextProviderProps {
  children: React.ReactNode;
}

export const HopeViewVerticalTabsContextProvider: React.FunctionComponent<
  IHopeViewVerticalTabsContextProviderProps
> = ({ children }) => {
  const [tabs, setTabs] = React.useState<HopeViewVerticalTab[]>([]);

  const pushTab = React.useCallback((tab: HopeViewVerticalTab) => {
    setTabs((prev) => [...prev, tab]);
  }, []);

  const removeTabsAfter = React.useCallback(
    (clickedTab: HopeViewVerticalTab) => {
      const index = tabs.indexOf(clickedTab);
      if (index >= tabs.length - 1) return;

      setTabs((prev) => {
        prev.splice(index + 1);
        return [...prev];
      });
    },
    [tabs]
  );

  const removeLastTab = React.useCallback(() => {
    setTabs((prev) => {
      prev.splice(prev.length - 1);
      return [...prev];
    });
  }, []);

  const clear = React.useCallback(() => {
    setTabs([]);
  }, []);

  return (
    <HopeViewVerticalTabsContextContext.Provider
      value={{
        tabs,
        pushTab,
        removeTabsAfter,
        clear,
        removeLastTab,
      }}
    >
      {children}
    </HopeViewVerticalTabsContextContext.Provider>
  );
};

export const useHopeViewVerticalTabsContext = () =>
  React.useContext(HopeViewVerticalTabsContextContext);
