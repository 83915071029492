import {
  Classes,
  Colors,
  Icon,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
  Spinner,
} from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useDebounce } from "ahooks";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { ERoutes } from "../AppRouter";
import { useCommonHooks } from "../hooks";
import { ETLCodes } from "../locales";

const StyledInputGroup = styled(InputGroup)`
  & .${Classes.INPUT} {
    background: ${Colors.WHITE};
    color: ${Colors.DARK_GRAY3};
    box-shadow: inset 1px 1px 1px 1px rgba(0, 0, 0, 0.1),
      inset -1px -2px 1px -1px rgba(0, 0, 0, 0.1) !important;
  }
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
  min-width: 200px;
`;

const ClickableIcon = styled(Icon)`
  height: 30px;
  display: flex;
  align-items: center;
  width: 25px;

  &:hover {
    color: ${Colors.GRAY3} !important;
    cursor: pointer;
  }
`;

function boldedText(text, shouldBeBold) {
  const textArray = text.split(RegExp(shouldBeBold, "ig"));
  const match = text.match(RegExp(shouldBeBold, "ig"));

  return (
    <span>
      {textArray.map((item, index) => (
        <>
          {item}
          {index !== textArray.length - 1 && match && <b>{match[index]}</b>}
        </>
      ))}
    </span>
  );
}

export interface ISearchFieldProps {}

export const SearchField: React.FunctionComponent<ISearchFieldProps> = () => {
  const { t, apis, router } = useCommonHooks();
  const [filter, setFilter] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [isPopOverFocused, setIsPopOverFocused] = React.useState(false);

  const debouncedFilter = useDebounce(filter, { wait: 500 });
  const isOpen = React.useMemo(
    () => !!debouncedFilter.trim() && (isFocused || isPopOverFocused),
    [debouncedFilter, isFocused, isPopOverFocused]
  );

  const search = React.useCallback(async () => {
    if (!isOpen) return;
    return await apis.globalSearch.search(debouncedFilter);
  }, [apis.globalSearch, debouncedFilter, isOpen]);
  const { data, isFetching } = useQuery(
    ["global-search", debouncedFilter],
    search
  );

  const suivis = React.useMemo(
    () =>
      data
        ?.filter((d) => d.type === "Suivi")
        .map((d) => (
          <MenuItem
            label={d.date}
            text={boldedText(
              `${d.nom}${!!d.prenom ? " " + d.prenom : ""}${
                !!d.description ? " - " + d.description : ""
              }`,
              debouncedFilter.trim()
            )}
            onClick={() => {
              router.push(
                `${ERoutes.inbox}/${d.creationUser}?idpersonne=${d.beneficiaryId}`
              );
              setIsPopOverFocused(false);
            }}
          />
        )),
    [data, debouncedFilter, router]
  );

  const personnes = React.useMemo(
    () =>
      data
        ?.filter((d) => d.type === "Personne")
        .map((d) => (
          <MenuItem
            text={boldedText(
              `${d.nom}${!!d.prenom ? " " + d.prenom : ""}${
                !!d.date ? " - " + d.date : ""
              }`,
              debouncedFilter.trim()
            )}
            onClick={() => {
              router.push(`${ERoutes.inbox}?idpersonne=${d.id}`);
              setIsPopOverFocused(false);
            }}
          />
        )),
    [data, debouncedFilter, router]
  );

  const entreprises = React.useMemo(
    () =>
      data
        ?.filter((d) => d.type === "Entreprise")
        .map((d) => (
          <MenuItem
            text={boldedText(
              `${d.nom}${!!d.description ? " - " + d.description : ""}`,
              debouncedFilter
            )}
            onClick={() => {
              router.push(`${ERoutes.beneficiaryCompanies}?idcompany=${d.id}`);
              setIsPopOverFocused(false);
            }}
          />
        )),
    [data, debouncedFilter, router]
  );

  const popoverContent = React.useMemo(() => {
    if (isFetching || !data) return <Spinner />;

    if (data?.length === 0)
      return (
        <NonIdealState title={t(ETLCodes.AucunResultat)} icon="zoom-out" />
      );

    return (
      <Menu>
        {suivis?.length > 0 && <MenuDivider title={t(ETLCodes.Suivis)} />}
        {suivis}
        {personnes?.length > 0 && <MenuDivider title={t(ETLCodes.Personnes)} />}
        {personnes}
        {entreprises?.length > 0 && (
          <MenuDivider title={t(ETLCodes.Entreprises)} />
        )}
        {entreprises}
      </Menu>
    );
  }, [data, entreprises, isFetching, personnes, suivis, t]);

  return (
    <Popover2
      inheritDarkTheme={false}
      isOpen={isOpen}
      hasBackdrop
      backdropProps={{
        onClick: () => setIsPopOverFocused(false),
      }}
      minimal
      position="bottom-left"
      autoFocus={false}
      content={
        <PopoverContent className={Classes.POPOVER_CONTENT}>
          {popoverContent}
        </PopoverContent>
      }
    >
      <StyledInputGroup
        placeholder={t(ETLCodes.Search)}
        leftIcon="search"
        type="search"
        dir="auto"
        value={filter}
        onInput={(e) => setFilter(e.currentTarget.value)}
        onFocus={() => {
          setIsFocused(true);
          setIsPopOverFocused(true);
        }}
        onBlur={() => setIsFocused(false)}
        rightElement={
          filter !== "" && (
            <ClickableIcon icon="cross" onClick={() => setFilter("")} />
          )
        }
      />
    </Popover2>
  );
};

export default SearchField;
