import { useWindowSize } from "nsitools-react";
import React from "react";

export function useSmallScreen() {
  const { width } = useWindowSize();
  const [smallScreen, setSmallScreen] = React.useState(false);

  React.useEffect(() => {
    if (width) {
      setSmallScreen(width < 1400);
    }
  }, [width]);

  return { smallScreen };
}
