import { useParams } from "react-router-dom";

export function useEditRouteParams() {
  const { id, tab, ...other } = useParams<{
    id: string;
    tab: string;
  }>();

  return {
    id,
    tab,
    other,
  };
}
