import { Button } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IMenuButtonProps extends ICustomButtonProps {}

export const MenuButton: React.FunctionComponent<IMenuButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent,
  text,
  hideText = true,
  small,
}) => {
  const { t } = useTl();
  return (
    <Button
      icon={IconNames.MENU}
      minimal={minimal}
      onClick={onClick}
      text={!hideText ? text || t(ETLCodes.Menu) : null}
      intent={intent}
      loading={loading}
      disabled={disabled}
      small={small}
    />
  );
};
