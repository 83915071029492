import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import styled from "styled-components";

import { ICustomButtonProps } from "./ICustomButtonProps";

const StyledButton = styled(Button)`
  &.smaller {
    min-height: 16px !important;
    min-width: 16px !important;
    padding: 0 !important;
  }
`;

export interface ISwitchButtonProps extends ICustomButtonProps {
  smaller?: boolean;
}

export const SwitchButton: React.FunctionComponent<ISwitchButtonProps> = ({
  minimal = true,
  onClick,
  loading,
  disabled,
  intent = Intent.NONE,
  text,
  small,
  smaller,
  icon,
}) => {
  return (
    <StyledButton
      icon={icon ?? IconNames.SwapHorizontal}
      minimal={minimal}
      onClick={onClick}
      text={text}
      intent={intent}
      loading={loading}
      disabled={disabled}
      small={small}
      className={smaller && "smaller"}
    />
  );
};

export default SwitchButton;
