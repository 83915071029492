import * as React from "react";
import { IButtonProps } from "@blueprintjs/core";
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";

export type DialogResult = "yes" | "no" | "ok" | "cancel";
export type DialogResultButtonType = Omit<IButtonProps, "onClick"> & {
  resultType: DialogResult;
};
type ConfirmDialogData = {
  title?: string;
  buttons?: Array<DialogResultButtonType>;
  message: string;
  onDialogClosed?: (result: DialogResult) => void;
  onConfirmed?: () => void;
};
export type ConfirmDialogDataPromise = Omit<
  ConfirmDialogData,
  "onConfirmed" | "onDialogClosed"
>;

interface IDialogContext {
  showDialog: (data: ConfirmDialogData) => Promise<DialogResult>;
  hideDialog: (result: DialogResult) => Promise<void>;
}

const DialogContext = React.createContext<IDialogContext>(null);

const dialogDataAtom = atom<ConfirmDialogData>({
  key: "dialogData",
  default: null,
});

interface DialogProviderProps {
  children: React.ReactNode;
}

const DialogProvider: React.FunctionComponent<DialogProviderProps> = ({
  children,
}) => {
  const setDialogData = useSetRecoilState(dialogDataAtom);

  const showDialog = React.useCallback(
    (data: ConfirmDialogData) => {
      let res;
      const promise = new Promise<DialogResult>((resolve) => {
        res = resolve;
      });
      const { ...other } = data;

      setDialogData({
        ...other,
        onDialogClosed: (result: DialogResult) => {
          res(result);
        },
      });
      return promise;
    },
    [setDialogData]
  );

  const getDialogData = useRecoilCallback(({ snapshot }) => async () => {
    return await snapshot.getPromise(dialogDataAtom);
  });
  const hideDialog = React.useCallback(
    async (result: DialogResult) => {
      const { onDialogClosed, onConfirmed } = await getDialogData();
      setDialogData(null);
      if (onDialogClosed) onDialogClosed(result);
      if ((result === "ok" || result === "yes") && onConfirmed) {
        onConfirmed();
      }
    },
    [getDialogData, setDialogData]
  );

  return (
    <DialogContext.Provider value={{ showDialog, hideDialog }}>
      {children}
    </DialogContext.Provider>
  );
};

export function useDialogData() {
  const dialogData = useRecoilValue(dialogDataAtom);
  const dialogMethods = useDialog();

  return {
    dialogData,
    ...dialogMethods,
  };
}

const useDialog = () => React.useContext(DialogContext);

export {DialogProvider, useDialog};
