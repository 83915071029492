import { Colors } from "@blueprintjs/core";
import { lighten } from "polished";

import assets from "./assets";

export interface IThemeData {
  siteTheme: SiteTheme;
  headerColor: string;
  mainBackgroundColor: string;
  menuColor: string;
  menuIconColor: string;
  navMenuWidth: number;
  titleColor: string;
  selectionMenuColor: string;
  userColor: string;
  headerName: string;
  highlightColor: string;
  logoUrl: string;
  logoWhiteUrl: string;
  bannerUrl: string;
  primaryColor: string;
  tableStrip: string;
  tableRowHover: string;
  searchFilterHighlightColor: string;
  searchFilterHighlightColorRGB: string;
  searchFilterHighlightBackgroundColor: string;
  mainTextColor: string;
}

const baseTheme: IThemeData = {
  siteTheme: "base",
  mainBackgroundColor: "#f0f0f0",
  primaryColor: "#7d003e",
  headerColor: "#333",
  menuColor: "#333",
  menuIconColor: Colors.WHITE,
  navMenuWidth: 240,
  selectionMenuColor: Colors.GRAY1,
  titleColor: "#333",
  userColor: "transparent",
  highlightColor: Colors.GRAY5,
  headerName: "Smile",
  logoUrl: assets.images.smileLogo,
  logoWhiteUrl: assets.images.smileLogoWhite,
  bannerUrl: assets.images.banner,
  tableStrip: lighten(0.6, Colors.FOREST5),
  tableRowHover: lighten(0.57, Colors.FOREST5),
  searchFilterHighlightColor: "#00afff",
  searchFilterHighlightColorRGB: "19 124 189",
  searchFilterHighlightBackgroundColor: lighten(0.6, "#00afff"),
  mainTextColor: "#4b5059",
};

export type SiteTheme = "base";

export function getTheme(type: SiteTheme) {
  switch (type) {
    case "base":
      return baseTheme;
    default:
      return baseTheme;
  }
}
