import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface IClearButtonProps extends ICustomButtonProps {
  tabIndex?: number;
}

export const ClearButton: React.FunctionComponent<IClearButtonProps> = ({
  minimal = true,
  onClick,
  disabled,
  loading,
  intent = Intent.NONE,
  tabIndex,
}) => {
  const { t } = useTl();

  return (
    <Button
      icon={IconNames.CROSS}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Clear)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};
