import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";

import { useTl } from "../../hooks";
import { ETLCodes } from "../../locales";
import { ICustomButtonProps } from "./ICustomButtonProps";

interface ICancelButtonProps extends ICustomButtonProps {}

export const CancelButton: React.FunctionComponent<ICancelButtonProps> = ({
  minimal = true,
  onClick,
  disabled,
  loading,
  intent = Intent.NONE,
  style,
  className,
}) => {
  const { t } = useTl();

  return (
    <Button
      icon={IconNames.RESET}
      minimal={minimal}
      onClick={onClick}
      text={minimal ? null : t(ETLCodes.Cancel)}
      intent={intent}
      loading={loading}
      disabled={disabled}
      style={style}
      className={className}
    />
  );
};
