import {
  Button,
  ButtonGroup,
  Classes,
  Colors,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { getIn } from "formik";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import { IFGContext, IFGCustomInputProps, useFGContext } from "nsitools-react";
import * as React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";

import { useCommonHooks } from "../../../hooks";
import { ClearButton } from "../../applicationButtons";
import { FGReadOnlyInput } from "../../formGenerator/FGReadOnlyInput";

const Container = styled.div<{ height: number }>`
  display: flex;
  flex: 1;
  & .${Classes.INPUT} {
    height: ${(props) => props.height}px;
  }
`;

const StyledTag = styled(Tag)`
  & a {
    color: ${Colors.WHITE};
  }
`;

const NamesContainer = styled.div`
  max-width: 850px;
  > * + * {
    margin-left: 5px;
  }
`;

export interface IUserSelectorFieldProps
  extends Omit<IFGCustomInputProps, "children"> {
  disabled?: boolean;
  allowMultipleSelection?: boolean;
  initialSearch?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  goToSelector?: (ctx?: IFGContext) => void;
  customItem?: {
    displayValue: string;
    onRemove?: () => void;
  };
}

export const UserSelectorField: React.FunctionComponent<
  IUserSelectorFieldProps
> = ({
  name,
  label = null,
  disabled = false,
  className,
  allowMultipleSelection = false,
  initialSearch = true,
  ref,
  goToSelector,
  customItem,
  ...fgCustomInputProps
}) => {
  const { apis } = useCommonHooks();
  const ctx = useFGContext();
  const { formik, editMode } = ctx;

  const value = React.useMemo(
    () => !!formik?.values && getIn(formik?.values, name),
    [formik?.values, name]
  );

  const hasValue = React.useMemo(
    () =>
      (allowMultipleSelection && !isEmpty(value)) ||
      (!allowMultipleSelection && !!value),
    [allowMultipleSelection, value]
  );
  const [currentSelectedIds, setSelectedIds] = React.useState<number[]>([]);

  const { data: displayNames, isFetching: loadingDisplayNames } = useQuery(
    ["users-display-names", currentSelectedIds],
    async () =>
      !!currentSelectedIds && apis.user.getDisplayNames(currentSelectedIds)
  );

  const clearSelection = React.useCallback(() => {
    formik?.setFieldValue(name, allowMultipleSelection ? [] : null, true);
    setSelectedIds([]);
  }, [allowMultipleSelection, formik, name]);

  React.useEffect(() => {
    if (value) {
      setSelectedIds(isArray(value) ? value : [value]);
    } else {
      setSelectedIds([]);
    }
  }, [formik.values, name, value]);

  const removeId = React.useCallback(
    (id: number) => {
      const newIds = [...currentSelectedIds];
      newIds.splice(currentSelectedIds.indexOf(id), 1);
      formik?.setFieldTouched(name);
      formik?.setFieldValue(name, newIds, true);
      setSelectedIds(newIds);
    },
    [formik, currentSelectedIds, name]
  );

  const getDisplayTag = React.useCallback(
    (id: number, name: string, tags: boolean) => {
      return tags ? (
        <StyledTag key={id} onRemove={editMode && (() => removeId(id))}>
          {name}
        </StyledTag>
      ) : (
        name
      );
    },
    [editMode, removeId]
  );

  const inputContainerCss = React.useMemo(() => {
    if (allowMultipleSelection) {
      return { padding: "0 0 0 0" };
    } else {
      return { padding: "0.4rem 0 0 0.6rem" };
    }
  }, [allowMultipleSelection]);

  const [tagBoxHeight, setTagBoxHeight] = React.useState(30);
  const tagBoxRef = React.useCallback((node) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((e, o) => {
      const height = e?.[0]?.target?.clientHeight ?? 30;
      setTagBoxHeight(height > 30 ? height : 30);
    });
    resizeObserver.observe(node);
  }, []);

  return (
    <Container height={tagBoxHeight}>
      <FGReadOnlyInput
        name={name}
        label={label}
        readonly={!editMode}
        leftElement={
          <div style={inputContainerCss} ref={tagBoxRef}>
            {loadingDisplayNames ? (
              <Spinner size={16} />
            ) : (
              <NamesContainer>
                {displayNames.map((dn) =>
                  getDisplayTag(dn.id, dn.text, allowMultipleSelection)
                )}
                {!!customItem && allowMultipleSelection && (
                  <StyledTag onRemove={editMode && customItem.onRemove}>
                    {customItem.displayValue}
                  </StyledTag>
                )}
              </NamesContainer>
            )}
          </div>
        }
        rightElement={
          editMode && (
            <ButtonGroup>
              {hasValue && (
                <ClearButton
                  onClick={clearSelection}
                  tabIndex={-1}
                  disabled={disabled}
                />
              )}
              <Button
                icon="people"
                minimal={true}
                onClick={() => goToSelector(ctx)}
                disabled={disabled}
                tabIndex={-1}
              />
            </ButtonGroup>
          )
        }
        {...fgCustomInputProps}
      />
    </Container>
  );
};
